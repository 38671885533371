import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  CircularProgress,
  Grid
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { options, optionsStore } from './chart';
import FilterButton from '../FilterButton';

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    zIndex: 999
  },
  chartContainer: {
    height: 400,
    marginTop: 20,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const PriorityGraph = props => {
  const { className, data, loading, handleChangeView, ...rest } = props;
  const classes = useStyles();
  const anchorRef = useRef(null);
  const anchorRefStore = useRef(null);
  const handleDownload = () => {
    const canvas = anchorRef.current.chartInstance.canvas;
    let fileName = 'ImportânciaValorLoja';
    const link = document.createElement('a');
    link.download = fileName + '.jpg';
    canvas.toBlob(function(blob) {
      link.href = URL.createObjectURL(blob);
      link.click();
    }, 'image/jpeg');

    const canvasStore = anchorRefStore.current.chartInstance.canvas;
    let fileNameStore = 'ImportânciaValorStore';
    const linkStore = document.createElement('a');
    linkStore.download = fileNameStore + '.jpg';
    canvasStore.toBlob(function(blob) {
      linkStore.href = URL.createObjectURL(blob);
      linkStore.click();
    }, 'image/jpeg');
  };
  const views = [
    { name: 'Subcategorias', id: 'subcategories', ext: 'subcategory_id' },
    { name: 'Fabricantes', id: 'manufacturers', ext: 'manufacturer_id' },
    { name: 'Marcas', id: 'brands', ext: 'brand_id' }
  ];
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        classes={{ action: classes.header }}
        title="Importância valor | Subcategorias"
        action={
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <FilterButton options={views} handleAction={handleChangeView} />
              <IconButton color="primary" onClick={handleDownload}>
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className={classes.chartContainer}>
              {data && !loading && (
                <Bar
                  data={{ labels: data.labels, datasets: data.datasets }}
                  options={options}
                  ref={anchorRefStore}
                />
              )}
              {loading && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px 0 20px 0'
                  }}>
                  <CircularProgress />
                </Grid>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className={classes.chartContainer}>
              {data && !loading && (
                <Bar
                  data={{ labels: data.labels, datasets: data.datasetsStore }}
                  options={optionsStore}
                  ref={anchorRef}
                />
              )}
              {loading && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px 0 20px 0'
                  }}>
                  <CircularProgress />
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PriorityGraph.propTypes = {
  className: PropTypes.string
};

export default PriorityGraph;
