import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';

function Graph({ data, legend, type, legend2 }) {
  const [graphData, setData] = React.useState({
    labels: data ? data.labels : ['selecione'],
    datasets: data ? data.datasets : [{ data: [100] }],
    options: data ? data.options : {}
  });

  useEffect(() => {
    if (data) setData(data);
  }, [data]);

  return (
    <>
      <Typography component="span" variant="h4">
        <b>{legend}</b>
      </Typography>

      <Doughnut
        data={graphData}
        options={graphData.options}
        height={type !== 'default' ? 300 : 200}
      />
      {type === 'compliance' && (
        <Typography component="span" variant="h4" style={{ fontSize: '2rem' }}>
          <b>{legend2}</b>
        </Typography>
      )}
    </>
  );
}

export default React.memo(Graph);
