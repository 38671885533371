import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  IconButton,
  CircularProgress
} from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/CloudDownload';

import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    zIndex: 999
  },
  chartContainer: {
    height: 400,
    marginTop: 20,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SalesGraph = props => {
  const { className, data, loading, ...rest } = props;
  const classes = useStyles();
  const anchorRef = useRef(null);
  const handleDownload = () => {
    const canvas = anchorRef.current.chartInstance.canvas;
    let fileName = 'EvoluçãoVendas';
    const link = document.createElement('a');
    link.download = fileName + '.jpg';
    canvas.toBlob(function(blob) {
      link.href = URL.createObjectURL(blob);
      link.click();
    }, 'image/jpeg');
  };
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        classes={{ action: classes.header }}
        title="Evolução Vendas"
        action={
          <IconButton color="primary" onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {data && !loading && (
            <Line
              data={data}
              options={options}
              id={'myChart'}
              ref={anchorRef}
            />
          )}
          {loading && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px 0 20px 0'
              }}>
              <CircularProgress />
            </Grid>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

SalesGraph.propTypes = {
  className: PropTypes.string
};

export default SalesGraph;
