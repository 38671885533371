import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import { DataTable, DataToolbar } from './components';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../services/api';
import reducers from '../../constants/reducers';
import { throwError } from 'rxjs';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1.5)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignIndustries: 'center',
    justifyContent: 'flex-end'
  },
  progress: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignIndustries: 'center',
    justifyContent: 'center',
    width: '100%'
  }
}));

const TableList = ({ history }) => {
  const classes = useStyles();

  const industries = useSelector(state => state.industries.data);
  const token = useSelector(state => state.user.token);
  const [page, setPage] = useState(0);
  //eslint-disable-next-line
  const [industriesPerPage, setIndustriesPerPage] = useState(10);
  const [total, setTotal] = useState(10);

  const [timeout, setTimeoutX] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    async function getIndustries() {
      setLoading(true);
      try {
        const response = await api.get('/industries', {
          params: { page: page + 1, industriesPerPage, api_token: token },
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
          }
        });
        if (response.data.success) {
          setTotal(response.data.data.total);
          dispatch({
            type: reducers.industries.list,
            industries: response.data.data.data
          });
        } else {
          throwError(response.data.error);
        }
      } catch (e) {
        alert(e);
        console.log(e);
      }
      setLoading(false);
    }
    getIndustries();
    // eslint-disable-next-line
  }, [page, industriesPerPage]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event, rows) => {
    setPage(0);
    setIndustriesPerPage(rows.key);
  };
  const handleView = industry_id => {
    history.push(`/industries/${industry_id}`);
  };

  const handleSearch = async text => {
    try {
      if (timeout) clearTimeout(timeout);
      setTimeoutX(
        setTimeout(async () => {
          setLoading(true);
          const response = await api.get('/industries', {
            params: { page: page + 1, industriesPerPage, q: text },
            headers: { Authorization: `Bearer ${token}` }
          });

          if (response.data.success) {
            setPage(0);
            setTotal(response.data.data.total);
            dispatch({
              type: reducers.industries.list,
              industries: response.data.data.data
            });
          } else {
            throwError(response.data.error);
          }
          setLoading(false);
        }, 300)
      );
    } catch (e) {
      alert(e);
    }
  };
  return (
    <div className={classes.root}>
      <DataToolbar history={history} handleSearch={handleSearch} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          {!loading && (
            <DataTable
              data={industries}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handleView={handleView}
              page={page}
              rowsPerPage={industriesPerPage}
              total={total}
            />
          )}
          {loading && (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default TableList;
