import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  appName: {
    color: 'whitesmoke',
    fontWeight: 'bold',
    fontSize: 20
  },
  menuButton: {
    marginRight: theme.spacing(2)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const industry = useSelector(state => state.industries.selected);
  const enterprise = useSelector(state => state.enterprises.selected);
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden mdDown>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Typography className={classes.appName} variant="h5">
            {process.env.REACT_APP_APP_NAME}
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        {enterprise && enterprise.logo && (
          <img
            src={`${process.env.REACT_APP_API_URL}${enterprise &&
              enterprise.logo}`}
            height="50"
            style={{ marginRight: 20 }}
            alt="logo"
          />
        )}
        {industry && industry.logo && (
          <img
            src={`${process.env.REACT_APP_API_URL}${industry && industry.logo}`}
            height="50"
            alt="logo"
          />
        )}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default React.memo(Topbar);
