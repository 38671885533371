import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Input,
  FormControl,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  ListItemText,
  Checkbox,
  Button
} from '@material-ui/core';
import api from 'services/api';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  },
  filterbar: {},
  header: {
    padding: '5px 10 5px 10'
  }
}));

const FilterBlock = ({ handleSubmit, loadingData, type }) => {
  const token = useSelector(state => state.user.token);
  const enterprise = useSelector(state => state.enterprises.selected);
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    subcategories: [],
    categories: [],
    brands: [],
    manufacturers: [],
    // pivots: [],
    periods: [],
    stores: []
  });
  const [filters, setFilters] = useState({
    enterprise: enterprise && enterprise.id,
    subcategory: [],
    category: [],
    brand: [],
    manufacturer: [],
    // pivot: [],
    period: [],
    store: [],
    store2: []
  });
  useEffect(
    () => {
      setFilters({ ...filters, enterprise: enterprise && enterprise.id });
    },
    //eslint-disable-next-line
    [enterprise]
  );

  async function getFilterOptions() {
    try {
      if (enterprise) {
        const response = await api.get('/spacetrack/filterOptions', {
          params: {
            enterprise: enterprise.id
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setLoading(false);
        if (response.data.data) {
          setFilterOptions(response.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    enterprise && enterprise.id && getFilterOptions();
    //eslint-disable-next-line
  }, [enterprise]);

  const handleChange = (event, type = null, plural = null, field = null) => {
    const pos = event.target.value.indexOf('select-all');
    if (pos !== -1) {
      handleSelectAll(type, plural, field);
    } else {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value
      });
    }
  };
  const handleSubmitFilter = () => {
    handleSubmit(filters);
  };
  const handleSelectAll = (type, plural, field) => {
    if (filters[type].length === filterOptions[plural].length)
      setFilters({
        ...filters,
        [type]: []
      });
    else
      setFilters({
        ...filters,
        [type]: filterOptions[plural].map(n => n[field])
      });
  };

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Paper className={classes.filterbar}>
          {!loading && !loadingData && (
            <Grid container justify="space-evenly" alignItems="center">
              {/* <Grid item lg={1} xs={6}>
                <FormControl className={classes.formControl} margin="dense"
                  size="small">
                  
                    Agrupamento Pivô
                  </InputLabel>
                  <Select
                    name="pivot"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.pivot || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.pivots.map(filter => (
                        <MenuItem value={filter.id} key={filter.id}>
                          <Checkbox
                            checked={filters.pivot.indexOf(filter.id) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Select
                    name="category"
                    multiple
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Categoria</em>;
                      }
                      return selected.join(', ');
                    }}
                    input={<Input id="grouped-select" />}
                    value={filters.category || []}
                    onChange={e =>
                      handleChange(e, 'category', 'categories', 'name')
                    }>
                    <MenuItem value="" disabled>
                      <em>Categoria</em>
                    </MenuItem>
                    <MenuItem value="select-all">
                      <Checkbox />
                      <ListItemText primary={'Selecionar Todos'} />
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.categories.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.category.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Select
                    name="subcategory"
                    multiple
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Subcategorias</em>;
                      }
                      return selected.join(', ');
                    }}
                    input={<Input id="grouped-select" />}
                    value={filters.subcategory || []}
                    onChange={e =>
                      handleChange(e, 'subcategory', 'subcategories', 'name')
                    }>
                    <MenuItem value="" disabled>
                      <em>Subcategorias</em>
                    </MenuItem>
                    <MenuItem value="select-all">
                      <Checkbox />
                      <ListItemText primary={'Selecionar Todos'} />
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.subcategories.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={
                              filters.subcategory.indexOf(filter.name) > -1
                            }
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Select
                    name="manufacturer"
                    multiple
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Fabricante</em>;
                      }
                      return selected.join(', ');
                    }}
                    input={<Input id="grouped-select" />}
                    value={filters.manufacturer || []}
                    onChange={e =>
                      handleChange(e, 'manufacturer', 'manufacturers', 'name')
                    }>
                    <MenuItem value="" disabled>
                      <em>Fabricante</em>
                    </MenuItem>
                    <MenuItem value="select-all">
                      <Checkbox />
                      <ListItemText primary={'Selecionar Todos'} />
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.manufacturers.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={
                              filters.manufacturer.indexOf(filter.name) > -1
                            }
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Select
                    name="brand"
                    multiple
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Marca</em>;
                      }
                      return selected.join(', ');
                    }}
                    input={<Input id="grouped-select" />}
                    value={filters.brand || []}
                    onChange={e => handleChange(e, 'brand', 'brands', 'name')}>
                    <MenuItem value="" disabled>
                      <em>Marca</em>
                    </MenuItem>
                    <MenuItem value="select-all">
                      <Checkbox />
                      <ListItemText primary={'Selecionar Todos'} />
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.brands.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.brand.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Select
                    name="period"
                    multiple
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Período</em>;
                      }
                      return filterOptions.periods
                        .filter(period => selected.includes(period.id))
                        .map(period => period.name)
                        .join(', ');
                    }}
                    input={<Input id="grouped-select" />}
                    value={filters.period || []}
                    onChange={e => handleChange(e, 'period', 'periods', 'id')}>
                    <MenuItem value="" disabled>
                      <em>Período</em>
                    </MenuItem>
                    <MenuItem value="select-all">
                      <Checkbox />
                      <ListItemText primary={'Selecionar Todos'} />
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.periods.map(filter => (
                        <MenuItem value={filter.id} key={filter.name}>
                          <Checkbox
                            checked={filters.period.indexOf(filter.id) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Select
                    name="store"
                    multiple
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Loja</em>;
                      }
                      return filterOptions.stores
                        .filter(store => selected.includes(store.id))
                        .map(store => store.name)
                        .join(', ');
                    }}
                    input={<Input id="grouped-select" />}
                    value={filters.store || []}
                    onChange={e => handleChange(e, 'store', 'stores', 'id')}>
                    <MenuItem value="" disabled>
                      <em>Loja</em>
                    </MenuItem>
                    <MenuItem value="select-all">
                      <Checkbox />
                      <ListItemText primary={'Selecionar Todos'} />
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.stores.map(filter => (
                        <MenuItem value={filter.id} key={filter.name}>
                          <Checkbox
                            checked={filters.store.indexOf(filter.id) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {type === 'period' && (
                <Grid item lg={1} xs={6}>
                  <FormControl
                    className={classes.formControl}
                    margin="dense"
                    size="small">
                    <Select
                      name="store2"
                      multiple
                      displayEmpty
                      renderValue={selected => {
                        if (selected.length === 0) {
                          return <em>Loja 2</em>;
                        }
                        return filterOptions.stores
                          .filter(store => selected.includes(store.id))
                          .map(store => store.name)
                          .join(', ');
                      }}
                      input={<Input id="grouped-select" />}
                      value={filters.store2 || []}
                      onChange={e => handleChange(e, 'store2', 'stores', 'id')}>
                      <MenuItem value="" disabled>
                        <em>Loja 2</em>
                      </MenuItem>
                      <MenuItem value="select-all">
                        <Checkbox />
                        <ListItemText primary={'Selecionar Todos'} />
                      </MenuItem>
                      {filterOptions &&
                        filterOptions.stores.map(filter => (
                          <MenuItem value={filter.id} key={filter.name}>
                            <Checkbox
                              checked={filters.store2.indexOf(filter.id) > -1}
                            />
                            <ListItemText primary={filter.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item lg={1} xs={6}>
                <FormControl
                  className={classes.formControl}
                  margin="dense"
                  size="small">
                  <Input
                    placeholder="Dia reposição"
                    type="number"
                    name="refill_date"
                    min={0}
                    inputProps={{ min: 0 }}
                    value={filters.refill_date || ''}
                    onChange={handleChange}></Input>
                </FormControl>
              </Grid>

              <Grid
                item
                lg={1}
                xs={6}
                style={{
                  margin: '20px 0 20px 20px'
                }}>
                <Button
                  onClick={handleSubmitFilter}
                  color="primary"
                  style={{ fontSize: 10 }}
                  variant="contained">
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          )}
          {(loading || loadingData) && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px 20px 20px 20px'
              }}>
              <CircularProgress />
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterBlock.propTypes = {
  className: PropTypes.string
};

export default React.memo(FilterBlock);
