import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Input,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Typography,
  Grid,
  CircularProgress,
  ListItemText,
  Checkbox,
  Slider,
  Button
} from '@material-ui/core';
import api from 'services/api';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  },
  filterbar: {},
  header: {
    padding: 20
  }
}));

const FilterBlock = ({ handleSubmit, loadingData }) => {
  const token = useSelector(state => state.user.token);
  const enterprise = useSelector(state => state.enterprises.selected);
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    enterprises: [],
    subcategories: [],
    flags: [],
    categories: [],
    types: [],
    brands: [],
    manufacturers: [],
    store_codes: [],
    segments: [],
    periods: [],
    regions: [],
    formats: [],
    states: []
  });
  const [filters, setFilters] = useState({
    enterprise: enterprise && enterprise.name,
    subcategory: [],
    flag: [],
    category: [],
    type: ['SALES'],
    brand: [],
    manufacturer: [],
    store_code: [],
    segment: [],
    period: [],
    region: [],
    format: [],
    base_weight: [0, 5],
    state: []
  });
  useEffect(
    () => {
      setFilters({ ...filters, enterprise: enterprise && enterprise.name });
      enterprise &&
        handleSubmit({ ...filters, enterprise: enterprise && enterprise.name });
    },
    //eslint-disable-next-line
    [enterprise]
  );
  function valuetext(value) {
    return `${value} Kg/L`;
  }
  async function getFilterOptions() {
    try {
      if (enterprise) {
        const response = await api.get('/charts/filterOptions', {
          params: {
            enterprise: enterprise.id
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setLoading(false);
        if (response.data.data) {
          setFilterOptions(response.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getFilterOptions();
    //eslint-disable-next-line
  }, [filters]);

  const handleChange = (event, newValue) => {
    setFilters({
      ...filters,
      [event.target.name || 'base_weight']: event.target.value || newValue
    });
  };
  const handleSubmitFilter = () => {
    handleSubmit(filters);
  };

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Paper className={classes.filterbar}>
          <div className={classes.header}>
            <Typography variant="h5" component="span">
              Filtros
            </Typography>
          </div>
          <Divider />
          {!loading && !loadingData && (
            <Grid container>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">
                    Sub-categoria
                  </InputLabel>
                  <Select
                    name="subcategory"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.subcategory || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.subcategories.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={
                              filters.subcategory.indexOf(filter.name) > -1
                            }
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Bandeira</InputLabel>
                  <Select
                    name="flag"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.flag || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.flags.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.flag.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Categoria</InputLabel>
                  <Select
                    name="category"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.category || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.categories.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.category.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">
                    Unidade de vendas
                  </InputLabel>
                  <Select
                    name="type"
                    multiple
                    renderValue={selected =>
                      filterOptions.types
                        .filter(type => selected.includes(type.id))
                        .map(type => type.name)
                        .join(', ')
                    }
                    input={<Input id="grouped-select" />}
                    value={filters.type || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.types.map(filter => (
                        <MenuItem value={filter.id} key={filter.id}>
                          <Checkbox
                            checked={filters.type.indexOf(filter.id) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Período</InputLabel>
                  <Select
                    name="period"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.period || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.periods.map(filter => (
                        <MenuItem value={filter.id} key={filter.id}>
                          <Checkbox
                            checked={filters.period.indexOf(filter.id) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Região</InputLabel>
                  <Select
                    name="region"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.region || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.regions.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.region.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Formato</InputLabel>
                  <Select
                    name="format"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.format || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.formats.map(filter => (
                        <MenuItem value={filter.label} key={filter.label}>
                          <Checkbox
                            checked={filters.format.indexOf(filter.label) > -1}
                          />
                          <ListItemText primary={filter.label} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Fabricante</InputLabel>
                  <Select
                    name="manufacturer"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.manufacturer || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.manufacturers.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={
                              filters.manufacturer.indexOf(filter.name) > -1
                            }
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Marca</InputLabel>
                  <Select
                    name="brand"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.brand || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.brands.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.brand.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Estado</InputLabel>
                  <Select
                    name="state"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    input={<Input id="grouped-select" />}
                    value={filters.state || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.states.map(filter => (
                        <MenuItem value={filter.label} key={filter.label}>
                          <Checkbox
                            checked={filters.state.indexOf(filter.label) > -1}
                          />
                          <ListItemText primary={filter.label} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Segmento</InputLabel>
                  <Select
                    name="segment"
                    multiple
                    renderValue={selected => selected.join(', ')}
                    value={filters.segment || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.segments.map(filter => (
                        <MenuItem value={filter.name} key={filter.name}>
                          <Checkbox
                            checked={filters.segment.indexOf(filter.name) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">
                    Código da Loja
                  </InputLabel>
                  <Select
                    name="store_code"
                    multiple
                    renderValue={selected =>
                      filterOptions.store_codes
                        .filter(code => selected.includes(code.id))
                        .map(code => code.name)
                        .join(', ')
                    }
                    value={filters.store_code || []}
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {filterOptions &&
                      filterOptions.store_codes.map(filter => (
                        <MenuItem value={filter.id} key={filter.id}>
                          <Checkbox
                            checked={filters.store_code.indexOf(filter.id) > -1}
                          />
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Typography gutterBottom></Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormControl className={classes.formControl}>
                  <Typography id="range-slider" gutterBottom>
                    Peso Base (Kg/L)
                  </Typography>
                  <Slider
                    value={filters.base_weight}
                    name="base_weight"
                    max={5}
                    step={0.1}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '20px 0 20px 0'
                }}>
                <Button
                  onClick={handleSubmitFilter}
                  color="primary"
                  variant="contained">
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          )}
          {(loading || loadingData) && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px 0 20px 0'
              }}>
              <CircularProgress />
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterBlock.propTypes = {
  className: PropTypes.string
};

export default React.memo(FilterBlock);
