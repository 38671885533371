import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FilterBar, NetworkPerformance, TopSKU } from './components/';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  marginLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 40
    }
  },
  marginBottom: {
    marginBottom: 40
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const profile = useSelector(state => state.user.user);
  const enterprise = useSelector(state => state.enterprises.selected);
  const handleChangePage = page => {
    setPage(page);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={3}>
          <Paper style={{ height: '100%', textAlign: 'center' }}>
            {enterprise && enterprise.logo ? (
              <img
                src={`${process.env.REACT_APP_API_URL}${(enterprise &&
                  enterprise.logo) ||
                  (profile.enterprise && profile.enterprise.logo)}`}
                height="100"
                alt="logo"
              />
            ) : enterprise && !enterprise.logo ? (
              <h2>Sem imagem</h2>
            ) : (
              <h2>Selecione um varejista</h2>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <FilterBar handleChangePage={handleChangePage} />
        </Grid>

        {page === 0 && <NetworkPerformance />}
        {page === 1 && <TopSKU />}
      </Grid>
    </div>
  );
};

export default React.memo(Dashboard);
