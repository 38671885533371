import reducers from '../constants/reducers';

const INITAL_STATE = { data: [], selected: null };

export default (state = INITAL_STATE, action) => {
  switch (action.type) {
    case reducers.industries.list:
      return {
        ...state,
        data: action.industries
      };
    case reducers.industries.selected:
      return {
        ...state,
        selected: action.data
      };
    case reducers.industries.create:
      return { ...state, data: [...state.data, action.industry] };
    case reducers.industries.update: {
      let industries = [...state.data];
      industries.map(industry =>
        industry._id === action.industry ? action.industry : industry
      );
      return { ...state, data: industries };
    }
    case reducers.industries.delete: {
      let industries = [...state.data];
      industries = industries.filter(industry => {
        return industry._id !== action.industry._id;
      });

      return { ...state, data: industries };
    }
    default:
      return state;
  }
};
