import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import api from 'services/api';
import {
  FilterBlock,
  TableDataPLN,
  ViewProducts,
  InfoData,
  Graph,
  ComparationTable,
  ProjectionDataTable
} from './components/';
import { FilterButton } from './components/Graph/components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  marginLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 40
    }
  },
  marginBottom: {
    marginBottom: 40
  },
  marginTop: {
    marginTop: 40
  }
}));

const SpaceTrack = () => {
  const classes = useStyles();
  const [data, setData] = useState({
    rows: [],
    columns: [
      { id: 'select', name: 'Selecionar', numeric: true },
      { id: 'auditorName', name: 'Auditor', numeric: false },
      { id: 'created_at', name: 'Criado', numeric: false },
      { id: 'clientName', name: 'Cliente', numeric: false },
      { id: 'store', name: 'Loja', numeric: false },
      { id: 'category', name: 'Categoria', numeric: false },
      { id: 'filePath', name: 'Arquivo', numeric: false },
      { id: 'view', name: 'Ação', numeric: true }
    ]
  });
  const [type, setType] = useState('default');
  const [compliance, setCompliance] = useState(null);
  const [projectionData1, setProjectionTableData1] = useState({
    rows: [],
    columns: [
      { id: 'ean', name: 'EAN', numeric: true },
      { id: 'name', name: 'Item', numeric: false },
      { id: 'manufacturer', name: 'Fabricante', numeric: false },
      { id: 'brand', name: 'Marca', numeric: false },
      { id: 'subcategory', name: 'SubCategoria', numeric: false },
      { id: 'recomendation', name: 'Recomendação', numeric: true },
      { id: 'projection', name: 'Projeção (R$)', numeric: true },
      { id: 'salesLost', name: 'Vendas Perdidas (R$)', numeric: true },
      { id: 'projectionProfit', name: 'Lucro Projetado', numeric: true },
      { id: 'profitLost', name: 'Lucro Perdido', numeric: true },
      { id: 'unitProjection', name: 'Unidades Projetadas', numeric: true },
      { id: 'unitLost', name: 'Unidades Perdidas', numeric: true },
      { id: 'investment', name: 'Investimento Exposição', numeric: true },
      { id: 'roii', name: 'ROII', numeric: false },
      { id: 'giro', name: 'Giro', numeric: true }
    ]
  });
  const [projectionData2, setProjectionTableData2] = useState({
    rows: [],
    columns: [
      { id: 'ean', name: 'EAN', numeric: true },
      { id: 'name', name: 'Item', numeric: false },
      { id: 'manufacturer', name: 'Fabricante', numeric: false },
      { id: 'brand', name: 'Marca', numeric: false },
      { id: 'subcategory', name: 'SubCategoria', numeric: false },
      { id: 'recomendation', name: 'Recomendação', numeric: true },
      { id: 'projection', name: 'Projeção (R$)', numeric: true },
      { id: 'salesLost', name: 'Vendas Perdidas (R$)', numeric: true },
      { id: 'projectionProfit', name: 'Lucro Projetado', numeric: true },
      { id: 'profitLost', name: 'Lucro Perdido', numeric: true },
      { id: 'unitProjection', name: 'Unidades Projetadas', numeric: true },
      { id: 'unitLost', name: 'Unidades Perdidas', numeric: true },
      { id: 'investment', name: 'Investimento Exposição', numeric: true },
      { id: 'roii', name: 'ROII', numeric: false },
      { id: 'giro', name: 'Giro', numeric: true }
    ]
  });
  const [comparationData, setComparationData] = useState({
    rows: [],
    columns: [
      { id: 'ean', name: 'EAN', numeric: true },
      { id: 'name', name: 'Item', numeric: false },
      { id: 'manufacturer', name: 'Fabricante', numeric: false },
      { id: 'brand', name: 'Marca', numeric: false },
      { id: 'subcategory', name: 'SubCategoria', numeric: false },
      { id: 'presence', name: 'Presença', numeric: false },
      { id: 'rupture', name: 'Ruptura', numeric: false },
      { id: 'unitSales', name: 'Vendas Unid', numeric: true },
      { id: 'space', name: 'Espaço', numeric: true },
      { id: 'projection', name: 'Projeção (R$)', numeric: true },
      { id: 'salesLost', name: 'Vendas Perdidas (R$)', numeric: true },
      { id: 'projectionProfit', name: 'Lucro Projetado', numeric: true },
      { id: 'profitLost', name: 'Lucro Perdido', numeric: true },
      { id: 'unitProjection', name: 'Unidades Projetadas', numeric: true },
      { id: 'unitLost', name: 'Unidades Perdidas', numeric: true },
      { id: 'investment', name: 'Investimento Exposição', numeric: true },
      { id: 'roii', name: 'ROII', numeric: false },
      { id: 'giro', name: 'Giro', numeric: true }
    ]
  });
  const [selectedRadio, setSelectedRadio] = useState([]);
  const [filters, setFilters] = useState(null);
  const [graphOpt, setGraphOpt] = useState({ id: 'manufacturer' });
  const [graphSalesOpt, setGraphSalesOpt] = useState({ id: 'SALES' });
  const [graphData1, setGraphData1] = useState(null);
  const [graphData2, setGraphData2] = useState(null);
  const [tableData1, setTableData1] = useState({
    rows: [],
    columns: [
      { id: 'ean', name: 'EAN', numeric: true },
      { id: 'name', name: 'Item', numeric: false },
      { id: 'manufacturer', name: 'Fabricante', numeric: false },
      { id: 'brand', name: 'Marca', numeric: false },
      { id: 'subcategory', name: 'SubCategoria', numeric: false },
      { id: 'salesUnit', name: 'Vendas unidade', numeric: true },
      { id: 'price', name: 'Preço', numeric: true },
      { id: 'cost', name: 'Custo', numeric: true },
      { id: 'fronts', name: 'Frentes', numeric: true },
      { id: 'totalUnit', name: 'Total Unidades', numeric: true },
      { id: 'spaceShare', name: 'Share Espaço', numeric: true },
      { id: 'storageDays', name: 'Dias de estoque', numeric: true },
      { id: 'stockDaySug', name: 'Reposição (Dias)', numeric: true },
      { id: 'status', name: 'Recomendação', numeric: false },
      { id: 'unitSug', name: 'Unidade Sugerido', numeric: true },
      { id: 'stockSug', name: 'Estoque Sugerido', numeric: true }
    ],
    graph: {}
  });
  const [tableData2, setTableData2] = useState({
    rows: [],
    columns: [
      { id: 'ean', name: 'EAN', numeric: true },
      { id: 'name', name: 'Item', numeric: false },
      { id: 'manufacturer', name: 'Fabricante', numeric: false },
      { id: 'brand', name: 'Marca', numeric: false },
      { id: 'subcategory', name: 'SubCategoria', numeric: false },
      { id: 'salesUnit', name: 'Vendas unidade', numeric: true },
      { id: 'price', name: 'Preço', numeric: true },
      { id: 'cost', name: 'Custo', numeric: true },
      { id: 'fronts', name: 'Frentes', numeric: true },
      { id: 'totalUnit', name: 'Total Unidades', numeric: true },
      { id: 'spaceShare', name: 'Share Espaço', numeric: true },
      { id: 'storageDays', name: 'Dias de estoque', numeric: true },
      { id: 'stockDaySug', name: 'Reposição (Dias)', numeric: true },
      { id: 'status', name: 'Recomendação', numeric: false },
      { id: 'unitSug', name: 'Unidade Sugerido', numeric: true },
      { id: 'stockSug', name: 'Estoque Sugerido', numeric: true }
    ],
    graph: {}
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsView, setProductsView] = useState([]);
  const token = useSelector(state => state.user.token);
  const enterprise = useSelector(state => state.enterprises.selected);

  async function fetchFiles() {
    try {
      const response = await api.get('/spacetrack', {
        params: {
          enterprise: enterprise.id
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.success) setData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchGraphData(filtersComing = null) {
    try {
      if (
        (selectedRadio.length === 1 && type === 'default') ||
        (selectedRadio.length > 1 && type !== 'default')
      ) {
        for (let i = 0; i < selectedRadio.length; i++) {
          const response = await api.post(
            '/spacetrack/graph',
            {
              id: selectedRadio[i],
              type: graphOpt.id,
              filters: (filtersComing && {
                ...filtersComing,
                store: filtersComing[`store${i === 1 ? 2 : ''}`]
              }) || { ...filters, store: filters[`store${i === 1 ? 2 : ''}`] },
              salesType: graphSalesOpt.id
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          if (response.data.success)
            i === 0
              ? setGraphData1(response.data.data)
              : setGraphData2(response.data.data);
        }
      } else {
        alert('Selecione os dados corretamente!');
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    document.body.style.zoom = '95%';
    enterprise && enterprise.id && fetchFiles();
    //eslint-disable-next-line
  }, [enterprise]);
  useEffect(() => {
    selectedRadio.length && graphOpt && graphSalesOpt && fetchGraphData();
    //eslint-disable-next-line
  }, [graphOpt, graphSalesOpt]);
  useEffect(() => {
    if (projectionData1.rows.length > 0 && projectionData2.rows.length > 0) {
      let rows = [];
      let columns = [
        { id: 'ean', name: 'EAN', numeric: true },
        { id: 'name', name: 'Item', numeric: false },
        { id: 'manufacturer', name: 'Fabricante', numeric: false },
        { id: 'brand', name: 'Marca', numeric: false },
        { id: 'subcategory', name: 'SubCategoria', numeric: false },
        { id: 'presence', name: 'Presença', numeric: false },
        { id: 'rupture', name: 'Ruptura', numeric: false },
        { id: 'unitSales', name: 'Vendas Unid', numeric: true },
        { id: 'space', name: 'Espaço', numeric: true },
        { id: 'projection', name: 'Projeção (R$)', numeric: true },
        { id: 'salesLost', name: 'Vendas Perdidas (R$)', numeric: true },
        { id: 'projectionProfit', name: 'Lucro Projetado', numeric: true },
        { id: 'profitLost', name: 'Lucro Perdido', numeric: true },
        { id: 'unitProjection', name: 'Unidades Projetadas', numeric: true },
        { id: 'unitLost', name: 'Unidades Perdidas', numeric: true },
        { id: 'investment', name: 'Investimento Exposição', numeric: true },
        { id: 'roii', name: 'ROII', numeric: false },
        { id: 'giro', name: 'Giro', numeric: true }
      ];
      let p1Eans = projectionData1.rows.map(row => row.info.ean);
      let union = projectionData2.rows.filter(
        row => p1Eans.indexOf(row.info.ean) === -1
      );
      union = [...union, ...projectionData1.rows];
      for (let row in union) {
        rows.push({
          info: union[row].info,
          values: {
            presence:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0
                ? 'AMBAS'
                : projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  ).length > 0
                ? data.rows.filter(
                    pln => pln.id == parseInt(selectedRadio[1])
                  )[0].store
                : data.rows.filter(
                    pln => pln.id == parseInt(selectedRadio[0])
                  )[0].store,
            rupture:
              projectionData2.rows.filter(
                p =>
                  p.info.ean === union[row].info.ean &&
                  p.values.recomendation === 'AUMENTAR'
              ).length > 0 &&
              projectionData1.rows.filter(
                p =>
                  p.info.ean === union[row].info.ean &&
                  p.values.recomendation === 'AUMENTAR'
              ).length > 0
                ? 'AMBAS'
                : projectionData2.rows.filter(
                    p =>
                      p.info.ean === union[row].info.ean &&
                      p.values.recomendation === 'AUMENTAR'
                  ).length > 0
                ? data.rows.filter(
                    pln => pln.id == parseInt(selectedRadio[1])
                  )[0].store
                : projectionData1.rows.filter(
                    p =>
                      p.info.ean === union[row].info.ean &&
                      p.values.recomendation === 'AUMENTAR'
                  ).length > 0
                ? data.rows.filter(
                    pln => pln.id == parseInt(selectedRadio[0])
                  )[0].store
                : '-',
            salesUnit:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.salesUnit > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.salesUnit /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.salesUnit -
                  1
                : '-',
            space:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.spaceShare > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.spaceShare /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.spaceShare -
                  1
                : '-',
            projection:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.projection > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.projection /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.projection -
                  1
                : '-',
            salesLost:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.salesLost > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.salesLost /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.salesLost -
                  1
                : '-',
            profit:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.projectionProfit > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.projectionProfit /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.projectionProfit -
                  1
                : '-',
            profitLost:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.profitLost > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.profitLost /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.profitLost -
                  1
                : '-',
            unitProjection:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.unitProjection > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.unitProjection /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.unitProjection -
                  1
                : '-',
            unitLost:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.unitLost > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.unitLost /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.unitLost -
                  1
                : '-',
            investment:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.investment > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.investment /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.investment -
                  1
                : '-',
            roii: 0,
            giro:
              projectionData2.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              ).length > 0 &&
              projectionData1.rows.filter(
                p => p.info.ean === union[row].info.ean
              )[0].values.giro > 0
                ? projectionData2.rows.filter(
                    p => p.info.ean === union[row].info.ean
                  )[0].values.giro /
                    projectionData1.rows.filter(
                      p => p.info.ean === union[row].info.ean
                    )[0].values.giro -
                  1
                : '-'
          }
        });
      }
      setComparationData({ columns, rows });
    }
    //eslint-disable-next-line
  }, [projectionData1, projectionData2]);

  const handleViewData = data => {
    setOpen(true);
    setProductsView(data);
  };

  const handleSubmitFilters = async filters => {
    try {
      setFilters(filters);
      fetchGraphData(filters);
      if (
        (selectedRadio.length === 1 && type === 'default') ||
        (selectedRadio.length > 1 && type === 'period')
      ) {
        for (let i = 0; i < selectedRadio.length; i++) {
          if (selectedRadio[i]) {
            setLoading(true);
            const response = await api.post(
              '/spacetrack/tablesInfo',
              {
                id: selectedRadio[i],
                filters
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
            if (response && response.data.success) {
              if (response.data.data.rows)
                i == 0
                  ? setTableData1(response.data.data)
                  : setTableData2(response.data.data);
              if (response.data.projectionTable.rows)
                i == 0
                  ? setProjectionTableData1(response.data.projectionTable)
                  : setProjectionTableData2(response.data.projectionTable);
            } else {
              alert('Error');
            }
            setLoading(false);
          } else {
            alert('Selecione os filtros corretamente');
          }
        }
      } else if (selectedRadio.length > 1 && type === 'compliance') {
        const response = await api.post(
          '/spacetrack/compliance',
          {
            pln1: selectedRadio[0],
            pln2: selectedRadio[1],
            enterprise: enterprise.id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.data) setCompliance(response.data);
      }
    } catch (er) {
      setLoading(false);
      console.log(er);
    }
  };

  const renderModules = () => {
    let html = '';
    if (
      compliance &&
      selectedRadio[0] &&
      compliance.modules[selectedRadio[0]]
    ) {
      Object.keys(compliance.modules[selectedRadio[0]]).forEach(function(
        module
      ) {
        html +=
          '<div style="background: lightgray; border-right: 3px solid black; margin-bottom: 15px"><div style="border-spacing: 0 5px">';
        Object.keys(compliance.modules[selectedRadio[0]][module])
          .reverse()
          .forEach(function(shelve) {
            html +=
              '<div style="display:flex; align-items: flex-end; height: 80px;border: 1px solid black; border-bottom: 3px solid black">';
            Object.keys(
              compliance.modules[selectedRadio[0]][module][shelve]
            ).forEach(function(position) {
              let color = 'lightgreen';
              if (
                compliance.edited.indexOf(`${module}-${shelve}-${position}`) !==
                  -1 ||
                compliance.deleted.indexOf(
                  compliance.modules[selectedRadio[0]][module][shelve][position]
                ) !== -1
              )
                color = 'yellow';
              let css = 'padding: 15px';
              if (
                compliance.base.filter(
                  p =>
                    p.ean ==
                    compliance.modules[selectedRadio[0]][module][shelve][
                      position
                    ]
                ).length
              ) {
                let width = compliance.base
                  .filter(
                    p =>
                      p.ean ==
                      compliance.modules[selectedRadio[0]][module][shelve][
                        position
                      ]
                  )[0]
                  .width.replace(',', '.');
                let height = compliance.base
                  .filter(
                    p =>
                      p.ean ==
                      compliance.modules[selectedRadio[0]][module][shelve][
                        position
                      ]
                  )[0]
                  .height.replace(',', '.');
                css = `width: ${parseFloat(width) * 4}px; height: ${
                  parseFloat(height) * 4 > 80 ? 80 : parseFloat(height) * 4
                }px;`;
              }
              html += `<div style="background-color: ${color}; ${css} border: 1px solid black; "></div>`;
            });
            html += '</div>';
          });
        html += '</div></div>';
      });
    }
    return html;
  };

  const handleChangeRadio = event => {
    if (type === 'default') setSelectedRadio([event.target.value]);
    else {
      if (selectedRadio.indexOf(event.target.value) !== -1)
        setSelectedRadio(
          selectedRadio.filter(val => val !== event.target.value)
        );
      else
        selectedRadio.length < 2 &&
          setSelectedRadio([...selectedRadio, event.target.value]);
    }
  };
  const handleChangeGraphOpt = id => {
    setGraphOpt(id);
  };
  const handleChangeGraphSalesOpt = id => {
    setGraphSalesOpt(id);
  };
  const opts = [
    { name: 'Fabricante', id: 'manufacturer' },
    { name: 'Marca', id: 'brand' },
    { name: 'Categoria', id: 'category' },
    { name: 'Subcategoria', id: 'subcategory' }
  ];
  const salesOpts = [
    { name: 'R$', id: 'SALES' },
    { name: 'UNIDADE', id: 'Sum of VENDAS_UNIDADE' },
    { name: 'VOLUME', id: 'VOLUME' }
  ];

  const handleChangeModeType = typeX => {
    setType(typeX);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            variant={type === 'default' ? 'contained' : 'outlined'}
            color={'primary'}
            style={{ fontSize: 12, width: '150px' }}
            onClick={() => handleChangeModeType('default')}>
            Análise de loja
          </Button>
          <Button
            variant={type === 'period' ? 'contained' : 'outlined'}
            color={'primary'}
            style={{ fontSize: 12, width: '150px' }}
            onClick={() => handleChangeModeType('period')}>
            Loja x Loja
          </Button>
          <Button
            variant={type === 'compliance' ? 'contained' : 'outlined'}
            color={'primary'}
            style={{ width: '150px' }}
            onClick={() => handleChangeModeType('compliance')}>
            Compliance
          </Button>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid item xs={12} lg={12}>
            <TableDataPLN
              title={'Spacetrack'}
              data={data}
              type={type}
              handleAction={handleViewData}
              handleChangeRadio={handleChangeRadio}
              selectedRadio={selectedRadio}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          <FilterBlock
            handleSubmit={handleSubmitFilters}
            loadingData={loading}
            type={type}
          />
        </Grid>
        {type === 'compliance' && selectedRadio[0] && compliance && (
          <Grid item xs={12}>
            <Paper
              style={{
                marginTop: 20,
                marginBottom: 20,
                padding: 15
              }}>
              <div
                dangerouslySetInnerHTML={{ __html: renderModules() }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  overflowX: 'auto'
                }}></div>
            </Paper>
            <Paper
              style={{
                textAlign: 'center',
                marginTop: 20,
                marginBottom: 20,
                padding: 15
              }}>
              <Grid container justify="center">
                <Grid item xs={12} lg={3}>
                  <Graph
                    legend2={`${compliance.graph.assertivity.legend}%`}
                    legend="Taxa de Acerto"
                    data={compliance.graph.assertivity.data}
                    type={'compliance'}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Graph
                    legend2={`${compliance.graph.created.legend}`}
                    legend="Novos itens"
                    data={compliance.graph.created.data}
                    type={'compliance'}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Graph
                    legend2={`${compliance.graph.deleted.legend}`}
                    legend="Delists/Ruptura"
                    data={compliance.graph.deleted.data}
                    type={'compliance'}
                  />
                </Grid>
              </Grid>
              <Typography>Itens PLN1: {compliance.qntPln1}</Typography>
              <Typography>Itens PLN2: {compliance.qntPln2}</Typography>
            </Paper>
          </Grid>
        )}
        {type === 'default' && (
          <Grid item xs={12} lg={12}>
            <Paper
              style={{
                textAlign: 'center',
                marginTop: 20,
                marginBottom: 20,
                padding: 15
              }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    marginBottom: '1em',
                    justifyContent: 'flex-end'
                  }}>
                  <FilterButton
                    options={salesOpts}
                    handleAction={handleChangeGraphSalesOpt}
                  />
                  <FilterButton
                    options={opts}
                    handleAction={handleChangeGraphOpt}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Graph
                    data={graphData1 && graphData1.sales}
                    type={'default'}
                    legend={`Vendas - ${(data &&
                      selectedRadio.length &&
                      data.rows.filter(
                        pln => pln.id == parseInt(selectedRadio[0])
                      )[0].store) ||
                      'NA'} `}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Graph
                    data={graphData1 && graphData1.share}
                    type={'default'}
                    legend={`Espaço - ${(data &&
                      selectedRadio.length &&
                      data.rows.filter(
                        pln => pln.id == parseInt(selectedRadio[0])
                      )[0].store) ||
                      'NA'} `}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Graph
                    type={'default'}
                    data={graphData1 && graphData1.rupture}
                    legend={`Ruptura - ${(data &&
                      selectedRadio.length &&
                      data.rows.filter(
                        pln => pln.id == parseInt(selectedRadio[0])
                      )[0].store) ||
                      'NA'} `}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {(type === 'period' || type === 'compliance') && (
          <Paper
            style={{
              textAlign: 'center',
              marginTop: 20,
              marginBottom: 20,
              padding: 10
            }}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  marginBottom: 15,
                  justifyContent: 'flex-end'
                }}>
                <FilterButton
                  options={salesOpts}
                  handleAction={handleChangeGraphSalesOpt}
                />
                <FilterButton
                  options={opts}
                  handleAction={handleChangeGraphOpt}
                />
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={type === 'compliance' ? 3 : 6}
                  style={{
                    borderRight: '1px solid lightgray',
                    borderBottom: '1px solid lightgray',
                    paddingBottom: type === 'compliance' ? 25 : 50,
                    paddingLeft: type === 'compliance' ? 35 : 70,
                    paddingRight: type === 'compliance' ? 30 : 60
                  }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h4"
                        component="span"
                        style={{ textAlign: 'center', fontWeight: 800 }}>
                        Vendas
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={type === 'compliance' ? 12 : 6}
                      style={{ paddingLeft: '0px' }}>
                      <Graph
                        data={graphData1 && graphData2 && graphData1.sales}
                        legend={` ${(data &&
                          selectedRadio.length > 1 &&
                          data.rows.filter(
                            pln => pln.id == parseInt(selectedRadio[0])
                          )[0].store) ||
                          'NA'} `}
                      />
                    </Grid>
                    {type !== 'compliance' && (
                      <Grid item xs={6} style={{ paddingRight: '0px' }}>
                        <Graph
                          data={graphData1 && graphData2 && graphData2.sales}
                          legend={` ${(data &&
                            selectedRadio.length > 1 &&
                            data.rows.filter(
                              pln => pln.id == parseInt(selectedRadio[1])
                            )[0].store) ||
                            'NA'} `}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{
                    borderBottom: '1px solid lightgray',
                    paddingBottom: 50,
                    paddingLeft: 70,
                    paddingRight: 60
                  }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h4"
                        component="span"
                        style={{ textAlign: 'center', fontWeight: 800 }}>
                        Espaço
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '0px' }}>
                      <Graph
                        data={graphData1 && graphData1.share}
                        legend={`${(data &&
                          selectedRadio.length > 1 &&
                          data.rows.filter(
                            pln => pln.id == parseInt(selectedRadio[0])
                          )[0].store) ||
                          'NA'} `}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: '0px' }}>
                      <Graph
                        data={graphData2 && graphData2.share}
                        legend={`${(data &&
                          selectedRadio.length > 1 &&
                          data.rows.filter(
                            pln => pln.id == parseInt(selectedRadio[1])
                          )[0].store) ||
                          'NA'} `}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {type === 'compliance' && (
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    style={{
                      borderBottom: '1px solid lightgray',
                      paddingBottom: 25,
                      paddingLeft: 35,
                      paddingRight: 30
                    }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          component="span"
                          style={{ textAlign: 'center', fontWeight: 800 }}>
                          Ruptura
                        </Typography>
                      </Grid>
                      <Grid item lg={12}>
                        <Graph
                          data={graphData1 && graphData1.rupture}
                          legend={`${(data &&
                            selectedRadio.length > 1 &&
                            data.rows.filter(
                              pln => pln.id == parseInt(selectedRadio[0])
                            )[0].store) ||
                            'NA'} `}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {type !== 'compliance' && (
                <Grid container style={{ marginTop: 50 }} justify="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      component="span"
                      style={{ textAlign: 'center', fontWeight: 800 }}>
                      Ruptura
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} style={{ paddingLeft: 50 }}>
                    <Graph
                      data={graphData1 && graphData1.rupture}
                      legend={`${(data &&
                        selectedRadio.length > 1 &&
                        data.rows.filter(
                          pln => pln.id == parseInt(selectedRadio[0])
                        )[0].store) ||
                        'NA'} `}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3} style={{ paddingRight: 50 }}>
                    <Graph
                      data={graphData2 && graphData2.rupture}
                      legend={`${(data &&
                        selectedRadio.length > 1 &&
                        data.rows.filter(
                          pln => pln.id == parseInt(selectedRadio[1])
                        )[0].store) ||
                        'NA'} `}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid container>
        {type === 'compliance' && compliance && (
          <Grid item xs={12} lg={12}>
            <InfoData
              title={`Nível item - ${(data &&
                selectedRadio.length &&
                data.rows.filter(pln => pln.id == parseInt(selectedRadio[0]))[0]
                  .store) ||
                'NA'}`}
              type="compliance"
              data={compliance && compliance.nivelItem}
            />
          </Grid>
        )}
        {type === 'period' && (
          <Grid item xs={12} lg={12}>
            <ComparationTable
              title={`Comparação - LOJA_1 X LOJA_2`}
              data={comparationData}
            />
          </Grid>
        )}
        {(type === 'period' || type === 'default') && (
          <Grid item xs={12} lg={12}>
            <ProjectionDataTable
              title={`Projeção - ${(data &&
                selectedRadio.length &&
                data.rows.filter(pln => pln.id == parseInt(selectedRadio[0]))[0]
                  .store) ||
                'NA'}`}
              data={projectionData1}
            />
          </Grid>
        )}
        {type === 'period' && (
          <Grid item xs={12} lg={12}>
            <ProjectionDataTable
              title={`Projeção - ${(data &&
                selectedRadio.length > 1 &&
                data.rows.filter(pln => pln.id == parseInt(selectedRadio[1]))[0]
                  .store) ||
                'NA'}`}
              data={projectionData2}
            />
          </Grid>
        )}
        {(type === 'period' || type === 'default') && (
          <Grid item xs={12} lg={12}>
            <InfoData
              title={`Nível item - ${(data &&
                selectedRadio.length &&
                data.rows.filter(pln => pln.id == parseInt(selectedRadio[0]))[0]
                  .store) ||
                'NA'}`}
              data={tableData1 && tableData1}
            />
          </Grid>
        )}
        {type === 'period' && (
          <Grid item xs={12} lg={12}>
            <InfoData
              title={`Nível item - ${(data &&
                selectedRadio.length > 1 &&
                data.rows.filter(pln => pln.id == parseInt(selectedRadio[1]))[0]
                  .store) ||
                'NA'}`}
              data={tableData2 && tableData2}
            />
          </Grid>
        )}

        <ViewProducts
          mustOpen={open}
          data={productsView}
          handleOpen={() => setOpen(!open)}
        />
      </Grid>
    </div>
  );
};

export default SpaceTrack;
