import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core';

import PerformanceIcon from '@material-ui/icons/TrendingUp';
import TopSkuIcon from '@material-ui/icons/FilterList';
import ShareIcon from '@material-ui/icons/Share';
import PriceIcon from '@material-ui/icons/LocalOffer';
import StoreIcon from '@material-ui/icons/Store';
import ReportIcon from '@material-ui/icons/List';
import MixIcon from '@material-ui/icons/Category';

const useStyles = makeStyles(theme => ({
  filterbar: { padding: 10 },

  root: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    overflowY: 'hidden'
  }
}));

const FilterBar = ({ handleChangePage }) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.filterbar} elevation={3} variant="outlined">
          <Grid container>
            <Grid item xs={12}>
              <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  handleChangePage(newValue);
                }}
                showLabels
                className={classes.root}>
                <BottomNavigationAction
                  label="Desempenho de rede"
                  icon={<PerformanceIcon />}
                />
                <BottomNavigationAction label="Top SKU" icon={<TopSkuIcon />} />
                <BottomNavigationAction label="Mix" icon={<MixIcon />} />
                <BottomNavigationAction label="Share" icon={<ShareIcon />} />
                <BottomNavigationAction label="Price" icon={<PriceIcon />} />
                <BottomNavigationAction label="Lojas" icon={<StoreIcon />} />
                <BottomNavigationAction label="Report" icon={<ReportIcon />} />
              </BottomNavigation>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterBar.propTypes = {
  className: PropTypes.string
};

export default React.memo(FilterBar);
