import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer, TextField } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { FilterButton } from './components';
const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

function desc(a, b, orderBy) {
  if (
    b.info[orderBy] < a.info[orderBy] ||
    b.values[orderBy] < a.values[orderBy]
  ) {
    return -1;
  }
  if (
    b.info[orderBy] > a.info[orderBy] ||
    b.values[orderBy] > a.values[orderBy]
  ) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, cols, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headCells = [];
  cols.map(col => [
    headCells.push({
      id: col.id,
      numeric: col.numeric,
      disablePadding: false,
      label: col.name
    })
  ]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={
              Math.random()
                .toString(36)
                .substring(2, 15) +
              Math.random()
                .toString(36)
                .substring(2, 15)
            }
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 50%'
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { title, total, zeroTotal, handleSearch, handleTop } = props;
  let options = [
    { id: 'all', name: 'Todos' },
    { id: 'reduction', name: 'TOP Redução' },
    { id: 'increase', name: 'TOP Aumento' }
  ];
  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {title}
      </Typography>
      <TextField
        label="Procurar: "
        onChange={handleSearch}
        style={{ marginRight: '25px' }}
      />
      <Typography style={{ marginRight: '35px', width: '150px' }}>
        Qtd de produtos: {total} |{' '}
      </Typography>
      <Typography style={{ width: '150px' }}>
        Vendas Zero: {zeroTotal}
      </Typography>
      <FilterButton options={options} handleAction={e => handleTop(e.id)} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function EnhancedTable({ title, data }) {
  const classes = useStyles();
  const [dynamicData, setDynamicData] = React.useState({
    rows: [],
    columns: data && data.columns
  });
  const [order, setOrder] = React.useState('asc');
  const [top5, setTop] = React.useState('all');
  const [orderBy, setOrderBy] = React.useState('ean');
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const total = data.total;
  const zeroTotal = data.zeroTotal;

  React.useEffect(() => {
    setDynamicData(data);
  }, [data]);

  React.useEffect(() => {
    let aux = 0;

    let filtered = data.rows.filter(row => {
      return (
        row.info.ean.indexOf(`${search}`) > -1 ||
        row.info.name.toLowerCase().indexOf(`${search.toLowerCase()}`) > -1 ||
        top5 !== 'all' ||
        (top5 == 'increase' && row.values.recomendation == 'AUMENTAR') ||
        (top5 == 'reduction' && row.values.recomendation == 'REDUZIR')
      );
    });

    setDynamicData({
      ...dynamicData,
      rows:
        top5 !== 'all'
          ? filtered
              .sort((a, b) => b.values.storageDays - a.values.storageDays)
              .slice(0, 5)
          : filtered
    });

    // eslint-disable-next-line
  }, [search, top5]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const rows = dynamicData.rows && Object.values(dynamicData.rows);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title={title}
          total={total}
          zeroTotal={zeroTotal}
          handleTop={setTop}
          handleSearch={handleSearch}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              cols={data.columns}
            />
            <TableBody>
              {data.totals && (
                <TableRow
                  hover
                  tabIndex={-1}
                  style={{ backgroundColor: 'white' }}>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="right">{data.totals.projection}</TableCell>
                  <TableCell align="right">{data.totals.salesLost}</TableCell>
                  <TableCell align="right">
                    {data.totals.projectionProfit}
                  </TableCell>
                  <TableCell align="right">{data.totals.profitLost}</TableCell>
                  <TableCell align="right">
                    {data.totals.unitProjection}
                  </TableCell>
                  <TableCell align="right">{data.totals.unitLost}</TableCell>
                  <TableCell align="right">{data.totals.investment}</TableCell>
                  <TableCell align="right">0</TableCell>
                  <TableCell align="right">{data.totals.giro}</TableCell>
                </TableRow>
              )}
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15)
                      }>
                      <TableCell align="right">{row.info.ean}</TableCell>
                      <TableCell align="left">{row.info.name}</TableCell>
                      <TableCell align="left">
                        {row.info.manufacturer}
                      </TableCell>
                      <TableCell align="left">{row.info.brand}</TableCell>
                      <TableCell align="left">{row.info.subcategory}</TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color:
                            row.values.recomendation === 'MANTER'
                              ? '#008000'
                              : row.values.recomendation === 'REDUZIR'
                              ? '#b1b100'
                              : '#ff4e4e'
                        }}>
                        {row.values.recomendation}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.projection.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.salesLost.toFixed(0)}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.projectionProfit.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.profitLost.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.unitProjection.toFixed(0)}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.unitLost.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row.values.investment.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">0</TableCell>
                      <TableCell align="right">
                        {row.values.giro.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>Nenhum dado encontrado</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
