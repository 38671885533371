import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import { DataTable, DataToolbar } from './components';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../services/api';
import reducers from '../../constants/reducers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1.5)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  progress: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }
}));

const BrandList = ({ history }) => {
  const classes = useStyles();

  const brands = useSelector(state => state.brands.data);
  const token = useSelector(state => state.user.token);
  const [page, setPage] = useState(0);
  //eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [total, setTotal] = useState(10);

  const [timeout, setTimeoutX] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    async function getBrands() {
      setLoading(true);
      try {
        const response = await api.get('/brands', {
          params: { page: page + 1, itemsPerPage },
          headers: { Authorization: `Bearer ${token}` }
        });
        setTotal(response.data.total);
        dispatch({
          type: reducers.brands.list,
          brands: response.data.data
        });
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    getBrands();
    // eslint-disable-next-line
  }, [page, itemsPerPage]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event, rows) => {
    setItemsPerPage(rows.key);
  };

  const handleSearch = async text => {
    try {
      if (timeout) clearTimeout(timeout);
      setTimeoutX(
        setTimeout(async () => {
          setLoading(true);
          const response = await api.get('/brands', {
            params: { page: page + 1, itemsPerPage, q: text },
            headers: { Authorization: `Bearer ${token}` }
          });
          setPage(0);
          setTotal(response.data.total);
          dispatch({
            type: reducers.brands.list,
            brands: response.data.data
          });
          setLoading(false);
        }, 300)
      );
    } catch (e) {
      alert(e);
    }
  };
  return (
    <div className={classes.root}>
      <DataToolbar history={history} handleSearch={handleSearch} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          {!loading && (
            <DataTable
              data={brands}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={itemsPerPage}
              total={total}
            />
          )}
          {loading && (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default BrandList;
