import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Form, UserList } from './components';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const FormView = ({ history, match }) => {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [data, setData] = useState(null);
  const [industries, setIndustries] = useState(null);
  async function getData(id) {
    try {
      const response = await api.get(`/enterprises/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.success) {
        setData(response.data.data);
      } else {
        alert(response.data.error);
      }
    } catch (e) {
      alert(e);
    }
  }
  async function getIndustries() {
    try {
      const response = await api.get(`/industries`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { select: true }
      });
      if (response.data.success) {
        setIndustries(response.data.data);
      } else {
        alert(response.data.error);
      }
    } catch (e) {
      alert(e);
    }
  }
  useEffect(() => {
    getIndustries();
    match.params.id && getData(match.params.id);
    // eslint-disable-next-line
  }, [match.params]);
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Form history={history} data={data && data} industries={industries} />
        </Grid>
        {match.params.id && (
          <Grid item xs={12} lg={6}>
            <UserList
              history={history}
              data={data && data.users}
              enterpriseID={match.params.id}
              update={() => getData(match.params.id)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FormView;
