import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  List,
  ListItemText,
  ListItem
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  destroyBtn: {
    marginLeft: 'auto'
  }
}));

const EnterpriseList = props => {
  const { className, history, data, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader={'Lista de Empresas Associadas'} title="Empresas" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List component="nav" aria-label="enterprises">
              {data &&
                data.map(item => (
                  <React.Fragment key={item.id}>
                    <ListItem
                      button
                      onClick={() => history.push(`/enterprises/${item.id}`)}>
                      <ListItemText primary={item.name} />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              {data && data.length === 0 && (
                <ListItem>
                  <ListItemText primary={`Nada encontrado.`} />
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

EnterpriseList.propTypes = {
  className: PropTypes.string
};

export default EnterpriseList;
