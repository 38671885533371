import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import ProductReducer from './ProductReducer';
import BrandReducer from './BrandReducer';
import EnterpriseReducer from './EnterpriseReducer';
import IndustryReducer from './IndustryReducer';

export default combineReducers({
  user: UserReducer,
  products: ProductReducer,
  brands: BrandReducer,
  enterprises: EnterpriseReducer,
  industries: IndustryReducer
});
