import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import DownloadIcon from '@material-ui/icons/CloudDownload';
// import SortIcon from '@material-ui/icons/Sort';
import InputIcon from '@material-ui/icons/Input';
import SpaceIcon from '@material-ui/icons/Fullscreen';
import StoreIcon from '@material-ui/icons/Apartment';
import { Profile, SidebarNav } from './components';
import { useDispatch, useSelector } from 'react-redux';
import reducers from 'constants/reducers';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  minify: {
    width: 60,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, minify, ...rest } = props;
  const classes = useStyles();
  const enterprises = useSelector(state => state.enterprises.data);
  const industries = useSelector(state => state.industries.data);
  const enterprise = useSelector(state => state.enterprises.selected);
  const industry = useSelector(state => state.industries.selected);
  const user = useSelector(state => state.user.user);
  const token = useSelector(state => state.user.token);
  const dispatch = useDispatch();
  async function handleSignOut() {
    dispatch({
      type: reducers.user.signOut
    });
    dispatch({
      type: reducers.enterprises.selected,
      data: false
    });
    dispatch({
      type: reducers.industries.selected,
      data: false
    });
  }
  async function getEnterprises(industry = null) {
    const response = await api.get('/enterprises/items', {
      params: {
        industry
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.data) {
      dispatch({
        type: reducers.enterprises.list,
        enterprises: response.data.data
      });
      // setEnterprises(response.data.data || []);
    }
  }
  async function getIndustries() {
    const response = await api.get('/industries/items', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.data) {
      dispatch({
        type: reducers.industries.list,
        industries: response.data.data
      });
      // setIndustries(response.data.data || []);
    }
  }
  useEffect(() => {
    (user.roles[0] === 'administrator' || user.roles[0] === 'industry') &&
      getIndustries() &&
      getEnterprises();
    //eslint-disable-next-line
  }, []);
  const handleChange = event => {
    if (event.target.name === 'industry') {
      getEnterprises(event.target.value);
      dispatch({
        type: reducers.industries.selected,
        data: industries.filter(ent => ent.id === event.target.value)[0]
      });
      dispatch({
        type: reducers.enterprises.selected,
        data: false
      });
    } else {
      dispatch({
        type: reducers.enterprises.selected,
        data: enterprises.filter(ent => ent.id === event.target.value)[0]
      });
    }
  };
  const pages = [
    // {
    //   title: 'Dashboard',
    //   href: '/dashboard',
    //   icon: <DashboardIcon />
    // },
    // {
    //   title: 'Sortimento',
    //   href: '/dashboard',
    //   icon: <SortIcon />
    // },
    {
      title: 'Spacetrack',
      href: '/spacetrack',
      icon: <SpaceIcon />
    },

    {
      title: 'Download',
      href: '/download',
      icon: <DownloadIcon />
    },
    user.roles[0] === 'administrator' && {
      title: 'Administrador',
      href: '/administrator',
      icon: <StoreIcon />
    },

    {
      title: 'Sair',
      href: '#',
      action: handleSignOut,
      icon: <InputIcon />
    }
  ];
  return (
    <Drawer
      anchor="left"
      classes={{ paper: !minify ? classes.drawer : classes.minify }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        {!minify && <Profile />}
        {!minify &&
          (user.roles[0] === 'industry' ||
            user.roles[0] === 'administrator') && (
            <Divider className={classes.divider} />
          )}
        {user.roles[0] === 'administrator' && (
          <FormControl className={classes.formControl}>
            <InputLabel>Indústria</InputLabel>
            <Select
              name="industry"
              value={(industry && industry.id) || ''}
              onChange={handleChange}>
              <MenuItem value="">
                <em>Geral</em>
              </MenuItem>
              {industries &&
                industries.map(filter => (
                  <MenuItem value={filter.id} key={filter.name}>
                    {filter.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {(user.roles[0] === 'industry' || user.roles[0] === 'administrator') &&
          !minify && (
            <FormControl className={classes.formControl}>
              <InputLabel>Varejista</InputLabel>
              <Select
                name="enterprise"
                value={(enterprise && enterprise.id) || ''}
                onChange={handleChange}>
                <MenuItem value="">
                  <em>Geral</em>
                </MenuItem>
                {enterprises &&
                  enterprises.map(filter => (
                    <MenuItem value={filter.id} key={filter.name}>
                      {filter.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        {!minify && <Divider className={classes.divider} />}
        <SidebarNav className={classes.nav} pages={pages} minify={minify} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  minify: PropTypes.bool.isRequired
};

export default React.memo(Sidebar);
