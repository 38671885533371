import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  IconButton,
  Grid,
  Input,
  ListItemText,
  Radio,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Link
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ViewIcon from '@material-ui/icons/CloudDownload';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, cols, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headCells = [];
  cols.map(col => [
    headCells.push({
      id: col.id,
      numeric: col.numeric,
      disablePadding: false,
      label: col.name
    })
  ]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 25%'
  },
  formControl: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '15%'
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {
    title,
    handleDateChange,
    selectedDate,
    selectedAuditor,
    selectedStore,
    auditors,
    stores,
    handleChange
  } = props;
  const uniqueAuditor = [...new Set(auditors)];
  const uniqueStore = [...new Set(stores)];

  return (
    <Toolbar className={clsx(classes.root)} variant="dense">
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {title}
      </Typography>
      <Grid container justify="space-around">
        <FormControl className={classes.formControl} margin="none" size="small">
          <Select
            name="auditor"
            displayEmpty
            input={<Input id="grouped-select" />}
            onChange={handleChange}
            style={{ fontSize: 12 }}
            value={selectedAuditor || ''}>
            <MenuItem value="">
              <em>Auditor</em>
            </MenuItem>
            {uniqueAuditor &&
              uniqueAuditor.map(filter => (
                <MenuItem value={filter} key={filter}>
                  <ListItemText primary={filter} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} margin="none" size="small">
          <Select
            name="store"
            displayEmpty
            input={<Input id="grouped-select" />}
            onChange={handleChange}
            style={{ fontSize: 12 }}
            value={selectedStore || ''}>
            <MenuItem value="">
              <em>Loja</em>
            </MenuItem>
            {uniqueStore &&
              uniqueStore.map(filter => (
                <MenuItem value={filter} key={filter}>
                  <ListItemText primary={filter} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            name="initialDate"
            variant="inline"
            format="dd/MM/yyyy"
            margin="none"
            id="date-picker-inline"
            style={{ fontSize: 12 }}
            value={selectedDate.initialDate}
            onChange={value => handleDateChange('initialDate', value)}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            name="finalDate"
            variant="inline"
            format="dd/MM/yyyy"
            margin="none"
            style={{ fontSize: 12 }}
            id="date-picker-inline-final"
            value={selectedDate.finalDate}
            onChange={value => handleDateChange('finalDate', value)}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  cell: {
    fontSize: 12
  }
}));

function EnhancedTable({
  title,
  data,
  handleChangeRadio,
  selectedRadio,
  type
}) {
  const classes = useStyles();
  const [dynamicData, setDynamicData] = React.useState({
    rows: [],
    columns: [
      { id: 'select', name: 'Selecionar', numeric: true },
      { id: 'auditorName', name: 'Auditor', numeric: false },
      { id: 'created_at', name: 'Criado', numeric: false },
      { id: 'clientName', name: 'Cliente', numeric: false },
      { id: 'store', name: 'Loja', numeric: false },
      { id: 'category', name: 'Categoria', numeric: false },
      { id: 'filePath', name: 'Arquivo', numeric: false },
      { id: 'view', name: 'Ação', numeric: true }
    ]
  });
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [filters, setFilter] = React.useState({ auditor: '', store: '' });
  const [selectedDate, setSelectedDate] = React.useState({
    initialDate: new Date(),
    finalDate: new Date()
  });

  useEffect(() => {
    setDynamicData(data);
  }, [data]);

  useEffect(() => {
    setDynamicData({
      ...dynamicData,
      rows: data.rows.filter(row => {
        let x = true;
        let y = true;
        if (filters.auditor) x = row.auditorName === filters.auditor;
        if (filters.store) x = row.store === filters.store;

        if (selectedDate) {
          y =
            moment(row.created_at).format('YYYY-MM-DD') >=
            moment(selectedDate.initialDate).format('YYYY-MM-DD');

          if (selectedDate.finalDate)
            y =
              y &&
              moment(row.created_at).format('YYYY-MM-DD') <=
                moment(selectedDate.finalDate).format('YYYY-MM-DD');
        }
        return x && y;
      })
    });
    // eslint-disable-next-line
  }, [filters, selectedDate]);

  const rows = dynamicData.rows && Object.values(dynamicData.rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (name, value) => {
    setSelectedDate({ ...selectedDate, [name]: value });
  };

  const handleChange = e => {
    setFilter({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title={title}
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
          auditors={data.rows && data.rows.map(data => data.auditorName)}
          stores={data.rows && data.rows.map(data => data.store)}
          selectedAuditor={filters.auditor}
          selectedStore={filters.store}
          handleChange={handleChange}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            padding={'none'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows && rows.length}
              cols={dynamicData.columns || []}
            />
            <TableBody>
              {rows &&
                stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        style={{ root: { padding: 0 } }}
                        key={Object.keys(dynamicData.rows)[index] + 'a'}>
                        <TableCell align="right">
                          {type === 'period' && (
                            <Typography component="span" variant="body2">
                              {selectedRadio[0] === row.id.toString()
                                ? 'Loja 1'
                                : (selectedRadio.length > 1 &&
                                    selectedRadio[1] === row.id.toString() &&
                                    'Loja 2') ||
                                  ''}
                            </Typography>
                          )}
                          {type === 'compliance' && (
                            <Typography component="span" variant="body2">
                              {selectedRadio[0] === row.id.toString()
                                ? 'PLN 1'
                                : (selectedRadio.length > 1 &&
                                    selectedRadio[1] === row.id.toString() &&
                                    'PLN 2') ||
                                  ''}
                            </Typography>
                          )}
                          {type === 'default' ? (
                            <Radio
                              checked={selectedRadio[0] === row.id.toString()}
                              onChange={handleChangeRadio}
                              value={row.id}
                              name="radio-button"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                          ) : (
                            <Checkbox
                              checked={
                                selectedRadio.length
                                  ? selectedRadio.indexOf(row.id.toString()) !==
                                      -1 && true
                                  : false
                              }
                              onChange={handleChangeRadio}
                              value={row.id}
                              name="radio-button[]"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.auditorName}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {moment(row.created_at).format('DD/MM/YYYY HH:MM')}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.clientName}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.store}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.category}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          className={classes.cell}>
                          <a
                            href={`${row.filePath}`}
                            alt="Download PLN"
                            target="__blank">
                            {row.filePath.split('/')[5]}
                          </a>
                        </TableCell>
                        <TableCell align="right" className={classes.cell}>
                          <Link href={`${row.filePath}`} target="__blank">
                            <IconButton>
                              <ViewIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>Nenhum dado encontrado</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10]}
          component="div"
          count={rows && rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
export default React.memo(EnhancedTable);
