import palette from 'theme/palette';

export const data = {
  labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
  datasets: [
    {
      label: 'This year',
      backgroundColor: palette.primary.main,
      data: [18, 5, 19, 27, 29, 19, 20]
    },
    {
      label: 'Last year',
      backgroundColor: palette.neutral,
      data: [11, 20, 12, 29, 30, 25, 13]
    }
  ]
};

export const options = {
  title: {
    display: true,
    text: 'Top 10 Produtos | VAREJISTA'
  },
  legend: {
    display: true,
    position: 'bottom'
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        stacked: false
      }
    ],
    yAxes: [
      {
        gridLines: false,
        scaleLabel: {
          display: false
        },
        type: 'linear',
        display: true,
        id: 'y-axis-1'
      },
      {
        gridLines: false,
        scaleLabel: {
          display: false
        },
        display: true,
        id: 'y-axis-2'
      }
    ]
  }
};

export const optionsStore = {
  title: {
    display: true,
    text: 'Top 10 Produtos | MERCADO'
  },
  legend: {
    display: true,
    position: 'bottom'
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        stacked: false
      }
    ],
    yAxes: [
      {
        gridLines: false,
        scaleLabel: {
          display: false
        },
        type: 'linear',

        id: 'y-axis-1'
      },
      {
        gridLines: false,
        scaleLabel: {
          display: false
        },

        id: 'y-axis-2'
      }
    ]
  }
};
