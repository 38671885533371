import React, { useState, useEffect } from 'react';
import { Link, Grid, SvgIcon, Typography } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import QRCode from 'easyqrcodejs';
import DownloadIcon from '@material-ui/icons/GetApp';
import FolderIcon from '@material-ui/icons/Folder';

export default function SimpleDialog({
  onClose,
  open,
  folderPath,
  fileName,
  handleDownload
}) {
  const [snackOpen, setSnackOpen] = useState(false);

  useEffect(() => {
    if (open && fileName) {
      setTimeout(() => {
        document.getElementById('qrCode').innerHTML = '';
        new QRCode(document.getElementById('qrCode'), {
          text: `${process.env.REACT_APP_URL}download-file-token?path=${folderPath}/${fileName.name}`,
          title: `${fileName.name}`,
          titleFont: 'bold 15px Arial',
          titleBackgroundColor: '#ffffff',
          titleHeight: 70,
          titleColor: 'black',
          titleTop: 25,
          subTitle: fileName.uploaded.toLocaleDateString(),
          subTitleFont: 'bold 13px Arial',
          subTitleBackgroundColor: '#ffffff',
          subTitleHeight: 70,
          subTitleColor: 'black',
          subTitleTop: 50,
          width: 350,
          height: 350,
          quietZone: 50,
          quietZoneColor: 'white'
        });
      }, 500);
    } else {
    }
  }, [open, fileName, folderPath]);
  const refCallback = node => {
    const canvas = document.querySelector(`#qrCode > canvas`);
    var url = canvas.toDataURL('image/png');
    var link = document.createElement('a');
    link.download = `QRCode-${fileName.name.replace('.', '')}.png`;
    link.href = url;
    link.click();
  };
  function copyTextToClipboard(e) {
    e.preventDefault();
    if (!navigator.clipboard) {
      handleCopy();
      return;
    }
    navigator.clipboard
      .writeText(
        `${process.env.REACT_APP_URL}download-file-token?path=${folderPath}/${fileName.name}`
      )
      .then(
        function() {
          setSnackOpen(true);
          console.log('Async: Copying to clipboard was successful!');
        },
        function(err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
  }
  const handleCopy = () => {
    var textArea = document.createElement('textarea');
    textArea.value = `${process.env.REACT_APP_URL}download-file-token?path=${folderPath}/${fileName.name}`;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
    setSnackOpen(true);
  };
  return (
    <Grid
      xs={12}
      lg={2}
      item
      style={{
        backgroundColor: 'white',
        marginTop: 15,
        marginBottom: 15,
        boxShadow:
          '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        borderRadius: 4
      }}>
      {open && (
        <div
          style={{
            padding: 20,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flex: 1
          }}>
          <div id={'qrCode'} style={{ marginBottom: 40, display: 'none' }}>
            {/* <h3>{fileName}</h3>
          <QRCode
            value={`${process.env.REACT_APP_URL}download-file-token?path=${folderPath}/${fileName}`}
            size={256}
            includeMargin={true}
          /> */}
          </div>
          {!fileName.dir ? (
            <SvgIcon style={{ fontSize: 60, marginTop: 20 }}>
              <path
                fill="currentColor"
                d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z"
              />
            </SvgIcon>
          ) : (
            <FolderIcon style={{ fontSize: 60, marginTop: 20 }} />
          )}
          {fileName && (
            <Typography
              variant="body1"
              style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
              {fileName.name}
            </Typography>
          )}

          {fileName && (
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {fileName.uploaded.toLocaleDateString()}
            </Typography>
          )}

          <br />
          <br />
          <br />
          <Link
            onClick={copyTextToClipboard}
            id="copy-url"
            href={'#'}
            title="Copiar link"
            alt="Copiar link">
            <SvgIcon style={{ fontSize: 35, marginTop: 20, cursor: 'pointer' }}>
              <path
                fill="currentColor"
                d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
              />
            </SvgIcon>
          </Link>
          <br></br>
          <br></br>
          <Link title="Download arquivo" alt="Download arquivo">
            <DownloadIcon
              onClick={() => handleDownload([{ ...fileName }])}
              style={{ fontSize: 35, marginTop: 20, cursor: 'pointer' }}
            />
          </Link>
          <br></br>
          <br></br>
          {fileName.dir && (
            <Link title="Download QrCode" alt="Download QrCode">
              <SvgIcon
                onClick={refCallback}
                style={{ fontSize: 35, marginTop: 20, cursor: 'pointer' }}>
                <path
                  fill="currentColor"
                  d="M4,4H10V10H4V4M20,4V10H14V4H20M14,15H16V13H14V11H16V13H18V11H20V13H18V15H20V18H18V20H16V18H13V20H11V16H14V15M16,15V18H18V15H16M4,20V14H10V20H4M6,6V8H8V6H6M16,6V8H18V6H16M6,16V18H8V16H6M4,11H6V13H4V11M9,11H13V15H11V13H9V11M11,6H13V10H11V6M2,2V6H0V2A2,2 0 0,1 2,0H6V2H2M22,0A2,2 0 0,1 24,2V6H22V2H18V0H22M2,18V22H6V24H2A2,2 0 0,1 0,22V18H2M22,22V18H24V22A2,2 0 0,1 22,24H18V22H22Z"
                />
              </SvgIcon>
            </Link>
          )}
          <Snackbar
            open={snackOpen}
            autoHideDuration={1500}
            message="Copiado com sucesso!"
            onClose={() => setSnackOpen(false)}></Snackbar>
        </div>
      )}
    </Grid>
  );
}
