import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import reducers from 'constants/reducers';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItemText,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import api from '../../../../services/api';
import { throwError } from 'rxjs';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  destroyBtn: {
    marginLeft: 'auto'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  filterbar: {},
  header: {
    padding: 20
  }
}));

const FormElement = props => {
  const { className, history, data, industries, ...rest } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(false);
  const token = useSelector(state => state.user.token);
  const [formState, setFormState] = useState({
    isValid: false,
    values: { password: '' },
    touched: {},
    errors: {}
  });

  const schema = !data
    ? {
        name: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 100,
            minimum: 3,
            message: 'é muito curto( mínimo de 3 caracteres) '
          }
        },
        email: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 100,
            minimum: 4,
            message: 'é muito curto( mínimo de 4 caracteres) '
          }
        },
        password: {
          presence: { allowEmpty: true, message: 'é obrigatório' },
          length: {
            maximum: 100,
            minimum: 8,
            message: 'é muito curto( mínimo de 8 caracteres) '
          }
        }
      }
    : {
        name: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 100,
            minimum: 3,
            message: 'é muito curto( mínimo de 3 caracteres) '
          }
        },
        email: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 100,
            minimum: 4,
            message: 'é muito curto( mínimo de 4 caracteres) '
          }
        },
        password: {
          length: {
            maximum: 100,
            minimum: 8,
            message: 'é muito curto( mínimo de 8 caracteres) '
          }
        }
      };

  useEffect(() => {
    if (data) {
      setFormState({
        isValid: true,
        values: {
          ...data,
          username: data.user.username,
          email: data.user.email
        },
        touched: {},
        errors: {}
      });
    }
  }, [data]);

  const handleBlur = () => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  };
  const handleFileChange = event => {
    setFile({ ...file, [event.target.name]: event.target.files[0] });
  };
  const handleChange = event => {
    event.persist();
    const name = event.target.name.split('.');
    if (name.length > 1) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name[0]]: {
            ...formState.values[name[0]],
            [name[1]]:
              event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        },
        touched: {
          ...formState.touched,
          [name[0]]: true
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name[0]]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        },
        touched: {
          ...formState.touched,
          [name[0]]: true
        }
      }));
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const dispatch = useDispatch();
  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    if (!validate(formState.values, schema)) {
      try {
        const response = !data
          ? await api.post(
              '/enterprises',
              {
                ...formState.values
              },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          : await api.patch(
              `/enterprises/${data.id}`,
              {
                ...formState.values
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
        const dataFile = new FormData();

        dataFile.append('file', file.file);
        dataFile.append('logo', file.logo);
        !data
          ? dataFile.append('enterpriseName', formState.values.name)
          : dataFile.append('enterpriseId', formState.values.id);
        await api.put('/files', dataFile, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.success) {
          setLoading(false);
          alert(`Varejista ${data ? 'atualizado' : 'criado'} com sucesso!`);
          dispatch({
            type: reducers.enterprises.create,
            enterprise: {
              ...response.data.data
            }
          });
          history.push('/administrator');
        } else {
          setFormState({
            ...formState,
            errors: {
              ...formState.errors,
              ...response.data.error.errors
            }
          });
          alert(response.data.error);
          throwError(response.data.error);
        }
      } catch (e) {
        alert('Email já existente');
      }
    }
    setLoading(false);
  };

  const handleDelete = async event => {
    event.preventDefault();
    try {
      if (window.confirm('Deseja realmente deletar esse registro?')) {
        setLoading(true);
        const response = await api.delete(`/enterprises/${data.id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.success) {
          setLoading(false);
          history.push('/enterprises');
        } else {
          throwError(response.data.error);
        }
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
    setLoading(false);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        encType="multipart/data-stream">
        <CardHeader
          subheader={!data ? 'Cadastro de Empresas' : 'Atualizar Empresa'}
          title="Empresas"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Indústria</InputLabel>
                  <Select
                    name="industry"
                    value={
                      formState.values.industry ||
                      formState.values.industry_id ||
                      ''
                    }
                    onChange={handleChange}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {industries &&
                      industries.map(filter => (
                        <MenuItem value={filter.id} key={filter.id}>
                          <ListItemText primary={filter.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome"
                  margin="dense"
                  name="name"
                  InputLabelProps={{ shrink: true }}
                  error={hasError('name')}
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  onChange={handleChange}
                  required
                  value={formState.values.name || ''}
                  variant="outlined"
                  onBlurCapture={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  InputLabelProps={{ shrink: true }}
                  error={hasError('email')}
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  onChange={handleChange}
                  required
                  value={formState.values.email || ''}
                  variant="outlined"
                  onBlurCapture={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Senha"
                  margin="dense"
                  name="password"
                  type="password"
                  InputLabelProps={{ shrink: true }}
                  error={hasError('password')}
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  onChange={handleChange}
                  value={formState.values.password || ''}
                  variant="outlined"
                  onBlurCapture={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Logo"
                  margin="dense"
                  name="logo"
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFileChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          {!loading ? (
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}>
              {!data ? 'Salvar' : 'Atualizar'}
            </Button>
          ) : (
            <CircularProgress />
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={() => history.goBack()}>
            Voltar
          </Button>
          {data && (
            <Button
              color="default"
              variant="contained"
              className={classes.destroyBtn}
              onClick={handleDelete}>
              Deletar
            </Button>
          )}
        </CardActions>
      </form>
    </Card>
  );
};

FormElement.propTypes = {
  className: PropTypes.string
};

export default FormElement;
