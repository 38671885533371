import React, { memo, useEffect } from 'react';
import { FileBrowser, FileView } from 'chonky';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import 'chonky/style/main.css';
import FileUpload from './components/FileUpload';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { CreateFolder, QRCode } from './components';
import './drive.css';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export default memo(() => {
  const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
  const [fileOpened, setFileOpened] = React.useState(false);
  const [createFolderOpen, setCreateFolderOpen] = React.useState(false);
  const [userToken, setUserToken] = React.useState(false);
  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);
  const [folderPath, setFolderPath] = React.useState([]);
  const [filesMap, setFiles] = React.useState({});
  const [currentFolder, setCurrentFolder] = React.useState(null);
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const industry = useSelector(state => state.industries.selected);
  const enterprise = useSelector(state => state.enterprises.selected);

  async function getUserToken() {
    const response = await api.get('download/token', {
      params: {
        enterprise: JSON.stringify(enterprise) || null,
        industry: JSON.stringify(industry) || null
      },
      headers: { Authorization: `Bearer ${token}` }
    });
    if (response.data.success) setUserToken(response.data.data);
    else alert('Erro ao buscar token');
  }

  useEffect(() => {
    getFiles();
    getUserToken();
    // eslint-disable-next-line
  }, [industry, enterprise]);

  async function getFiles() {
    const response = await api.get('/download', {
      params: {
        enterprise: JSON.stringify(enterprise) || null,
        industry: JSON.stringify(industry) || null
      },
      headers: { Authorization: `Bearer ${token}` }
    });
    if (response.data.success) {
      setFiles(response.data.data);
      setCurrentFolder(response.data.parentFolder);
      setFolderPath([response.data.parentFolder]);
    }
    // else alert('Erro ao buscar arquivos');
  }

  // Define a handler for "open file" action
  const handleFileOpen = file => {
    setQrCodeOpen(true);
    setFileOpened({
      name: file.name,
      uploaded: file.modDate,
      dir: file.isDir,
      childrenIds: file.childrenIds
    });
  };
  const handleFolderOpen = file => {
    if (file.isDir) {
      if (folderPath.indexOf(file.name) !== -1)
        setFolderPath(folderPath.slice(0, folderPath.indexOf(file.name) + 1));
      else setFolderPath([...folderPath, file.name]);
      setCurrentFolder(file.id);
    }
  };

  const handleAddFiles = files => {
    let newData = Object.assign({}, filesMap);
    for (let i = 0; i < files.length; i++) {
      if (
        !filesMap[files[i].name.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')]
      ) {
        newData = {
          ...newData,
          [currentFolder]: {
            ...newData[currentFolder],
            childrenIds: [
              ...newData[currentFolder].childrenIds,
              files[i].name.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')
            ]
          },
          [files[i].name.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')]: {
            id: files[i].name.toLowerCase().replace(/[^a-z0-9_+|-]/gi, ''),
            name: `${files[i].name
              .split('.')[0]
              .replace(/[^a-z0-9_+|-]/gi, '')}.${
              files[i].name.split('.')[files[i].name.split('.').length - 1]
            }`,
            isDir: false,
            parentId: currentFolder,
            childrenIds: [],
            modDate: new Date()
          }
        };
      }
    }
    setFiles(newData);
  };
  const handleAddFolder = folderName => {
    if (!filesMap[folderName.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')]) {
      setFiles({
        ...filesMap,
        [currentFolder]: {
          ...filesMap[currentFolder],
          childrenIds: [
            ...filesMap[currentFolder].childrenIds,
            folderName.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')
          ]
        },
        [folderName.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')]: {
          id: folderName.toLowerCase().replace(/[^a-z0-9_+|-]/gi, ''),
          name: folderName.replace(/[^a-z0-9_+|-]/gi, ''),
          isDir: true,
          modDate: new Date(),
          parentId: currentFolder,
          childrenIds: []
        }
      });
      alert('Enviado com sucesso');
    } else {
      alert('Pasta ja existente');
    }
  };
  const handleDownloadFile = async file => {
    const response = await api.get('download/file', {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
      params: {
        path: `${folderPath.slice(1).join('/')}/${file[0].name}`,
        enterprise: JSON.stringify(enterprise),
        industry: JSON.stringify(industry)
      }
    });
    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download =
      file[0].isDir &&
      (file[0].childrenIds.length === 0 || file[0].childrenIds.length > 1)
        ? file[0].name + '.zip'
        : file[0].childrenIds.length === 1 &&
          !filesMap[file[0].childrenIds[0]].isDir
        ? filesMap[file[0].childrenIds[0]].name
        : file[0].childrenIds.length >= 1 &&
          filesMap[file[0].childrenIds[0]].isDir
        ? file[0].name + '.zip'
        : file[0].name;
    link.click();
  };
  async function handleChangeUserToken(e) {
    const response = await api.post(
      'download/token',
      {
        userToken,
        enterprise: JSON.stringify(enterprise),
        industry: JSON.stringify(industry)
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    if (response.data.success) {
      alert('Atualizado');
    } else {
      alert('Erro ao mudar token');
    }
  }
  async function handleDeleteFile(file) {
    if (window.confirm('Deseja realmente apagar esse arquivo?')) {
      const response = await api.post(
        'download/delete',
        {
          path: `${folderPath.slice(1).join('/')}/${file[0].name}`,
          enterprise: JSON.stringify(enterprise),
          industry: JSON.stringify(industry)
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      if (response.data.success) {
        alert('Deletado');

        setFiles({
          ...filesMap,
          [currentFolder]: {
            ...filesMap[currentFolder],
            childrenIds: filesMap[currentFolder].childrenIds.filter(
              child => child !== file[0].id
            )
          },
          [file[0].id]: {
            ...filesMap[file[0].id],
            parentId: false
          }
        });
      } else {
        alert('Erro deletar arquivo');
      }
    }
  }

  // Render the file browser
  const InstallationComponent = () => {
    const folder = filesMap[currentFolder];
    const folderChain = [];
    let files = [];
    if (folder) {
      let currentFolder = folder;
      while (currentFolder) {
        folderChain.unshift(currentFolder);
        const parentId = currentFolder.parentId;
        currentFolder = parentId ? filesMap[parentId] : null;
      }
      if (folder.childrenIds) {
        files = folder.childrenIds.map(id => {
          return {
            ...filesMap[id],
            modDate: new Date(filesMap[id].modDate)
          };
        });
      }
    }
    return (
      <div style={{ height: 540 }}>
        <FileBrowser
          files={files}
          fillParentContainer={true}
          folderChain={folderChain}
          onFileOpen={handleFolderOpen}
          onFileSingleClick={handleFileOpen}
          onDeleteFiles={handleDeleteFile}
          view={FileView.Details}
          options={{
            showHidden: false,
            foldersFirst: true,
            showRelativeDates: false,
            disableTextSelection: true
          }}
          onFolderCreate={() => setCreateFolderOpen(true)}
          onDownloadFiles={handleDownloadFile}
          onUploadClick={() => setFileUploadOpen(true)}
        />
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <TextField
            fullWidth
            label="Meu token de acesso"
            margin="dense"
            name="token"
            required
            style={{ backgroundColor: 'white' }}
            onChange={e => setUserToken(e.target.value)}
            value={userToken || ''}
            variant="outlined"
          />
          <Button variant="contained" onClick={handleChangeUserToken}>
            Mudar token
          </Button>
        </Grid>
        <Grid item xs={12} lg={10}>
          <Paper style={{ height: '100%' }}>{InstallationComponent()}</Paper>
        </Grid>
        <FileUpload
          onClose={() => setFileUploadOpen(false)}
          open={fileUploadOpen}
          folder={[...folderPath]}
          handleAddFiles={handleAddFiles}
        />
        <CreateFolder
          onClose={() => setCreateFolderOpen(false)}
          open={createFolderOpen}
          files={filesMap}
          folder={[...folderPath]}
          handleAddFolder={handleAddFolder}
        />
        <QRCode
          onClose={() => setQrCodeOpen(false)}
          open={qrCodeOpen}
          fileName={fileOpened}
          handleDownload={handleDownloadFile}
          folderPath={folderPath.slice(1).join('/')}
        />
      </Grid>
    </div>
  );
});
