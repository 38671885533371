import React, { useState } from 'react';
import {
  CircularProgress,
  DialogTitle,
  Dialog,
  TextField,
  Button
} from '@material-ui/core';
import api from 'services/api';
import { useSelector } from 'react-redux';

export default function SimpleDialog({
  onClose,
  open,
  folder,
  handleAddFolder,
  files
}) {
  const token = useSelector(state => state.user.token);
  const [folderName, setFolderName] = useState(null);
  const [loading, setLoading] = useState(false);
  const industry = useSelector(state => state.industries.selected);
  const enterprise = useSelector(state => state.enterprises.selected);
  const handleClose = () => {
    onClose(false);
  };
  const handleFolderChange = e => {
    setFolderName(e.target.value);
  };

  const handleSubmit = async e => {
    try {
      if (
        folderName &&
        folderName.replace(/[^a-z0-9_+|-]/gi, '').length > 1 &&
        !files[folderName.toLowerCase().replace(/[^a-z0-9_+|-]/gi, '')]
      ) {
        setLoading(true);
        const dataFile = new FormData();
        dataFile.append('newFolderName', folderName);
        folder = folder.splice(1);
        folder = folder.join('/');
        dataFile.append('folder', folder);
        dataFile.append('enterprise', JSON.stringify(enterprise));
        dataFile.append('industry', JSON.stringify(industry));

        const res = await api.post('download/folder', dataFile, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (res.data.success) {
          onClose(false);
          setFolderName(null);
          handleAddFolder(folderName);
        } else alert('Erro ao enviar, tente novamente');
        setLoading(false);
      } else {
        alert('Preencha os dados corretamente ');
      }
    } catch (e) {
      alert('Erro ao criar pasta ');
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle id="simple-dialog-title">Criar pasta</DialogTitle>
      <div
        style={{
          padding: 20,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: 1
        }}>
        <TextField
          fullWidth
          label="Nome da pasta"
          margin="dense"
          inputProps={{ multiple: true }}
          name="folder"
          type="text"
          InputLabelProps={{ shrink: true }}
          onChange={handleFolderChange}
          variant="outlined"
        />
        {!loading ? (
          <Button onClick={handleSubmit}>Enviar</Button>
        ) : (
          <CircularProgress />
        )}
      </div>
    </Dialog>
  );
}
