import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  List,
  ListItemText,
  ListItem,
  IconButton,
  CircularProgress,
  Button,
  TextField,
  DialogTitle,
  ListItemSecondaryAction,
  Dialog
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import api from 'services/api';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  root: {},
  destroyBtn: {
    marginLeft: 'auto'
  }
}));

function SimpleDialog({ onClose, open, enterpriseID, user, update }) {
  const initial = {
    name: user.username || '',
    email: user.email || '',
    password: '',
    enterpriseID
  };
  useEffect(() => {
    setData({
      name: user.username || '',
      email: user.email || '',
      password: '',
      enterpriseID
    });
  }, [user, enterpriseID]);
  const token = useSelector(state => state.user.token);
  const [data, setData] = React.useState(initial);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    onClose(false);
  };
  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    try {
      if (!user.id && data.password.length < 8)
        alert('Senha precisa ter no mínimo 8 caracteres');
      else if (user.id && data.password && data.password.length < 8)
        alert('Senha precisa ter no mínimo 8 caracteres');
      else if (
        data.email &&
        !data.email.match(
          '^([0-9a-zA-Z]+([_.-]?[0-9a-zA-Z]+)*@[0-9a-zA-Z]+[0-9,a-z,A-Z,.,-]*(.){1}[a-zA-Z]{2,4})+$'
        )
      )
        alert('Email inválido');
      else if (data.name && data.email) {
        setLoading(true);
        const res = !user.id
          ? await api.post('enterprises/syncUser', data, {
              headers: { Authorization: `Bearer ${token}` }
            })
          : await api.patch(`enterprises/syncUser/${user.id}`, data, {
              headers: { Authorization: `Bearer ${token}` }
            });
        if (res.data.success) {
          alert('Enviado com sucesso');
          onClose(false);
          update();
          setData(initial);
        } else alert(`Erro: ${res.data.error}`);
        setLoading(false);
      } else {
        alert('Preencha os dados corretamente');
      }
    } catch (e) {
      console.log(e);
      alert(e);
      setLoading(false);
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle id="simple-dialog-title">Cadastrar novo usuário</DialogTitle>
      <div
        style={{
          padding: 20,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: 1
        }}>
        <TextField
          fullWidth
          label="Nome"
          margin="dense"
          name="name"
          type="text"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          variant="outlined"
          value={data.name}
        />
        <TextField
          fullWidth
          label="Email"
          margin="dense"
          name="email"
          type="email"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          variant="outlined"
          value={data.email}
        />
        <TextField
          fullWidth
          label="Senha"
          margin="dense"
          name="password"
          type="password"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          variant="outlined"
        />
        {!loading ? (
          <Button onClick={handleSubmit}>Enviar</Button>
        ) : (
          <CircularProgress />
        )}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
const UserList = props => {
  const token = useSelector(state => state.user.token);
  const { className, history, data, enterpriseID, update, ...rest } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(false);
  const handleDelete = async id => {
    try {
      if (window.confirm('Deseja realmente apagar este registro?')) {
        const res = await api.delete(`enterprises/syncUser/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (res.data.success) {
          alert('Deletado com sucesso');
          update();
        } else alert('Erro ao enviar, tente novamente');
      }
    } catch (e) {
      console.log(e);
      alert('Erro ao enviar, tente novamente');
    }
  };
  const handleClose = value => {
    setOpen(false);
  };
  const handleClickOpen = user => {
    setOpen(true);
    setUser(user);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        enterpriseID={enterpriseID}
        user={user}
        update={update}
      />
      <CardHeader
        subheader={'Lista de Usuários Associados'}
        title="Usuários"
        action={
          <IconButton aria-label="add" onClick={handleClickOpen}>
            <AddIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List component="nav" aria-label="users">
              {data &&
                data.map(item => (
                  <React.Fragment key={item.id}>
                    <ListItem button onClick={() => handleClickOpen(item)}>
                      <ListItemText primary={`${item.username}`} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => handleDelete(item.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              {data && data.length === 0 && (
                <ListItem>
                  <ListItemText primary={`Nada encontrado.`} />
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

UserList.propTypes = {
  className: PropTypes.string
};

export default UserList;
