import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ mustOpen, handleOpen, data }) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={mustOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpen}
        maxWidth={'xl'}
        fullScreen
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">{'Produtos'}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>EAN</TableCell>
                  <TableCell align="right">Posição</TableCell>
                  <TableCell align="right">Prateleira</TableCell>
                  <TableCell align="right">Módulo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {row.ean}
                    </TableCell>
                    <TableCell align="right">{row.position}</TableCell>
                    <TableCell align="right">{row.shelve}</TableCell>
                    <TableCell align="right">{row.module}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
