import reducers from '../constants/reducers';

const INITAL_STATE = { data: [], selected: { name: '' } };

export default (state = INITAL_STATE, action) => {
  switch (action.type) {
    case reducers.enterprises.list:
      return {
        ...state,
        data: action.enterprises
      };
    case reducers.enterprises.selected:
      return {
        ...state,
        selected: action.data || { name: '' }
      };
    case reducers.enterprises.create:
      return { ...state, data: [...state.data, action.enterprise] };
    case reducers.enterprises.update: {
      let enterprises = [...state.data];
      enterprises.map(enterprise =>
        enterprise._id === action.enterprise ? action.enterprise : enterprise
      );
      return { ...state, data: enterprises };
    }
    case reducers.enterprises.delete: {
      let enterprises = [...state.data];
      enterprises = enterprises.filter(enterprise => {
        return enterprise._id !== action.enterprise._id;
      });

      return { ...state, data: enterprises };
    }
    default:
      return state;
  }
};
