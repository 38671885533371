export default {
  user: {
    signUp: 'USER_SIGNUP',
    signIn: 'USER_SIGNIN',
    signOut: 'USER_SIGNOUT',
    isAuthenticating: 'USER_ISAUTHENTICATING'
  },
  products: {
    create: 'PRODUCT_CREATED',
    list: 'PRODUCT_LIST',
    delete: 'PRODUCT_DELETED',
    update: 'PRODUCT_UPDATED'
  },
  brands: {
    create: 'BRAND_CREATED',
    list: 'BRAND_LIST',
    delete: 'BRAND_DELETED',
    update: 'BRAND_UPDATED'
  },
  enterprises: {
    create: 'ENTERPRISE_CREATED',
    selected: 'ENTERPRISE_SELECTED',
    list: 'ENTERPRISE_LIST',
    delete: 'ENTERPRISE_DELETED',
    update: 'ENTERPRISE_UPDATED'
  },
  industries: {
    create: 'INDUSTRY_CREATED',
    selected: 'INDUSTRY_SELECTED',
    list: 'INDUSTRY_LIST',
    delete: 'INDUSTRY_DELETED',
    update: 'INDUSTRY_UPDATED'
  }
};
