import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { AuthRouteWithLayout, RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  EnterpriseList as EnterpriseListView,
  EnterpriseForm,
  IndustryList as IndustryListView,
  IndustryForm,
  Dashboard,
  SpaceTrack,
  Download,
  SignIn as SignInView,
  SignInToken as SignInTokenView,
  NotFound as NotFoundView,
  Administrator as AdministratorView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/spacetrack" />
      <AuthRouteWithLayout
        component={Dashboard}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <AuthRouteWithLayout
        component={Download}
        exact
        layout={MainLayout}
        path="/download"
      />
      <AuthRouteWithLayout
        component={SpaceTrack}
        exact
        layout={MainLayout}
        path="/spacetrack"
      />
      <AuthRouteWithLayout
        component={AdministratorView}
        exact
        layout={MainLayout}
        path="/administrator"
      />

      <AuthRouteWithLayout
        component={EnterpriseListView}
        exact
        layout={MainLayout}
        path="/enterprises"
      />
      <AuthRouteWithLayout
        component={EnterpriseForm}
        exact
        layout={MainLayout}
        path="/enterprises/create"
      />
      <AuthRouteWithLayout
        component={EnterpriseForm}
        exact
        layout={MainLayout}
        path="/enterprises/:id"
      />

      <AuthRouteWithLayout
        component={IndustryListView}
        exact
        layout={MainLayout}
        path="/industries"
      />
      <AuthRouteWithLayout
        component={IndustryForm}
        exact
        layout={MainLayout}
        path="/industries/create"
      />
      <AuthRouteWithLayout
        component={IndustryForm}
        exact
        layout={MainLayout}
        path="/industries/:id"
      />

      <RouteWithLayout
        component={SignInTokenView}
        exact
        layout={MinimalLayout}
        path="/download-file-token"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
