import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TableData, PriorityGraph } from './components/';
import { FilterBlock } from 'components';
import api from 'services/api';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  marginLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 40
    }
  },
  marginBottom: {
    marginBottom: 40
  }
}));
const TopSKU = () => {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [loading, setLoading] = useState(false);

  const [priorityGraphData, setSalesPriorityGraphData] = useState(false);
  const [rawData, setRawData] = useState({ rows: [], columns: [] });
  const [rawStoreData, setRawStoreData] = useState({ rows: [], columns: [] });
  const [filters, setFilters] = useState(null);
  const [view, setView] = useState({ name: 'Preço(Kg)', id: 'kgPerPrice' });
  const handleSubmitFilters = async filters => {
    setFilters(filters);
    setLoading(true);
    try {
      const responseP = await api.get('/charts/topsku', {
        params: { ...filters, priceFilter: [view.id, view.ext] },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (responseP.data) {
        setSalesPriorityGraphData({
          labels: responseP.data.labels,
          datasets: responseP.data.data,
          datasetsStore: responseP.data.dataStore
        });

        setRawData({
          columns: responseP.data.columns,
          rows: responseP.data.rawData
        });
        setRawStoreData({
          columns: responseP.data.columns,
          rows: responseP.data.rawDataStore
        });
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };
  const handleChangeView = async view => {
    setLoading(true);
    setView(view);
    try {
      const responseP = await api.get('/charts/topsku', {
        params: { ...filters, priceFilter: [view.id] },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (responseP.data) {
        setSalesPriorityGraphData({
          labels: responseP.data.labels,
          datasets: responseP.data.data,
          datasetsStore: responseP.data.dataStore
        });
        setRawData({
          columns: responseP.data.columns,
          rows: responseP.data.rawData
        });
        setRawStoreData({
          columns: responseP.data.columns,
          rows: responseP.data.rawDataStore
        });
      }
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };
  return (
    <>
      <Grid item lg={12} xs={12} className={classes.marginBottom}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FilterBlock
              handleSubmit={handleSubmitFilters}
              loadingData={loading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PriorityGraph
              data={priorityGraphData}
              loading={loading}
              handleChangeView={handleChangeView}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableData
              title={'Dados do varejista'}
              data={rawData}
              orderFilter={(filters && filters.type) || 'totalSales'}
            />
          </Grid>
          <Grid item xs={12}>
            <TableData
              title={'Dados do Mercado'}
              data={rawStoreData}
              orderFilter={(filters && filters.type) || 'totalSales'}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(TopSKU);
