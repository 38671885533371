import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IndustryIcon from '@material-ui/icons/Apartment';
import EnterpriseIcon from '@material-ui/icons/HomeWork';
import MarketIcon from '@material-ui/icons/AccountBalance';
import EANIcon from '@material-ui/icons/AddShoppingCart';
import {
  Typography,
  CircularProgress,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'center',
    padding: '30%',
    margin: '5%',
    cursor: 'pointer'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  }
}));

function SimpleDialog({ onClose, open, typeBase }) {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [enterprises, setEnterprises] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [data, setData] = useState({});
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  async function getEnterprises(industry = null) {
    const response = await api.get('/enterprises/items', {
      params: {
        industry
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.data) {
      setEnterprises(response.data.data || []);
    }
  }
  async function getIndustries() {
    const response = await api.get('/industries/items', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.data) {
      setIndustries(response.data.data || []);
    }
  }
  useEffect(() => {
    typeBase === true && getEnterprises() && getIndustries();
    //eslint-disable-next-line
  }, [typeBase]);
  const handleClose = () => {
    setData({});
    onClose(false);
  };
  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };
  const handleChange = e => {
    if (e.target.name === 'industry') getEnterprises(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async e => {
    try {
      if (file && (typeBase !== 'medida' ? data.enterprise : true)) {
        setLoading(true);
        const dataFile = new FormData();
        dataFile.append('file', file);
        typeBase === true && dataFile.append('enterpriseId', data.enterprise);
        let res = null;
        if (typeBase !== 'medida')
          res = await api.put('files', dataFile, {
            headers: { Authorization: `Bearer ${token}` }
          });
        else
          res = await api.post('base_products', dataFile, {
            headers: { Authorization: `Bearer ${token}` }
          });
        if (res.data.success) {
          alert('Enviado com sucesso');
          onClose(false);
          setFile(null);
        } else alert('Erro ao enviar, tente novamente');
        setLoading(false);
      } else {
        alert('Preencha os dados corretamente');
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle id="simple-dialog-title">
        Carregar banco de arquivos de{' '}
        {typeBase === true
          ? 'varejista'
          : typeBase !== false
          ? typeBase
          : 'mercado'}
      </DialogTitle>
      <div
        style={{
          padding: 20,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: 1
        }}>
        {typeBase === true && (
          <FormControl className={classes.formControl}>
            <InputLabel>Indústria</InputLabel>
            <Select
              name="industry"
              value={data.industry || ''}
              onChange={handleChange}>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {industries &&
                industries.map(filter => (
                  <MenuItem value={filter.id} key={filter.name}>
                    {filter.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {typeBase === true && (
          <FormControl className={classes.formControl}>
            <InputLabel>Varejista</InputLabel>
            <Select
              name="enterprise"
              value={data.enterprise || ''}
              onChange={handleChange}>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {enterprises &&
                enterprises.map(filter => (
                  <MenuItem value={filter.id} key={filter.name}>
                    {filter.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label="Arquivo"
          margin="dense"
          name="file"
          type="file"
          inputProps={{ accept: '.csv' }}
          InputLabelProps={{ shrink: true }}
          onChange={handleFileChange}
          variant="outlined"
        />
        {!loading ? (
          <Button onClick={handleSubmit}>Enviar</Button>
        ) : (
          <CircularProgress />
        )}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default function Administrator({ history }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [typeBase, setTypeBase] = React.useState(false);

  const handleClose = value => {
    setOpen(false);
    setTypeBase(false);
  };
  const handleClickOpen = (type = false) => {
    setTypeBase(type);
    setOpen(true);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{
            padding: 10,
            backgroundColor: 'white',
            textAlign: 'center',
            marginTop: 10,
            marginLeft: '2.5%',
            marginRight: '2.5%'
          }}>
          Cadastros de BI|Acessos
        </Typography>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper
          className={classes.card}
          onClick={() => history.push('/industries')}>
          <IndustryIcon fontSize="large" />
          <Typography variant="h4">Indústrias</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper
          className={classes.card}
          onClick={() => history.push('/enterprises')}>
          <EnterpriseIcon fontSize="large" />
          <Typography variant="h4">Varejistas</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{
            padding: 10,
            backgroundColor: 'white',
            textAlign: 'center',
            marginTop: 10,
            marginLeft: '1.8%',
            marginRight: '1.8%'
          }}>
          Cadastros de Bases
        </Typography>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper className={classes.card} onClick={() => handleClickOpen(false)}>
          <MarketIcon fontSize="large" />
          <Typography variant="h4">Base Mercado</Typography>
        </Paper>
        <SimpleDialog onClose={handleClose} open={open} typeBase={typeBase} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper className={classes.card} onClick={() => handleClickOpen(true)}>
          <MarketIcon fontSize="large" />
          <Typography variant="h4">Base Varejista</Typography>
        </Paper>
        <SimpleDialog onClose={handleClose} open={open} typeBase={typeBase} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper
          className={classes.card}
          onClick={() => handleClickOpen('medida')}>
          <EANIcon fontSize="large" />
          <Typography variant="h4">Base de Medidas</Typography>
        </Paper>
        <SimpleDialog onClose={handleClose} open={open} typeBase={typeBase} />
      </Grid>
    </Grid>
  );
}
